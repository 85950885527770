.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
  max-width: 792px;
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  .wrapper {
    gap: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    grid-template-columns: 1fr;
    gap: 50px;
  }
}
