.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  border: 1px solid var(--color-border-light);
}
.styledDropdown {
  padding: 10px 18px;
  border-radius: 8px;
}
.dropdownMain {
  min-width: calc(100%);
  height: 0;
  overflow: hidden;
  border-radius: 12px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 10px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease-in-out;
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  border: 1px solid var(--color-border-light);
}

.selectedValue {
  text-transform: capitalize;
  width: 100%;
  color: var(--color-grey-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.556%;
}

.icon {
  cursor: pointer;
  color: inherit;
  margin-left: auto;
  color: var(--Base-100);
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.listItem {
  padding: 8px 18px;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.4s ease;
  white-space: nowrap;
  color: var(--color-grey-text);
  text-transform: uppercase;
  width: 100%;
  color: var(--color-grey-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.556%;
}

.listItem.active,
.listItem:hover {
  background-color: var(--PrimaryDefault);
  color: var(--color-grey-text);
}
.listItem:hover {
  background-color: var(--Base-300);
  color: var(--color-grey-text);
}
.lightTheme .listItem.active {
  color: var(--color-grey-text);
}
.lightTheme .listItem:hover {
  background-color: var(--PrimaryDefault);
  color: var(--color-grey-text);
}
@media only screen and (max-width: 520px) {
  .selectedValue {
    font-size: 14px;
  }
  .listItem {
    font-size: 13px;
  }
}
