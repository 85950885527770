.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 56px 0;
}

.form > * {
  width: 100%;
  max-width: unset;
}

.form input {
  max-width: unset;
}

.form .btn {
  justify-content: center;
}
