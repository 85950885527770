.pagination {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 0;
}

.pagination .paginationItem {
  display: flex;
  width: 36px;
  height: 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 6px;

  color: var(--color-grey-text2);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  text-transform: capitalize;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .pagination .paginationItem {
    width: 36px;
    height: 36px;
    font-size: 13px;
  }
}

.pagination .paginationItem.active {
  background: var(--color-primary);
  color: var(--white-color);
}
.lightTheme .paginationItem.active {
  color: var(--white);
}
.pagination .paginationItem.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.2em solid var(--white-color);
  border-top: 0.2em solid var(--white-color);
}

.arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.arrow.right {
  transform: rotate(45deg);
}
