.row {
  transition: 0.3s;
  border-radius: 10px;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 80px 35px;
  align-items: center;
  gap: 0;
  padding: 0 5px;
}
.infoContainer {
  display: flex;
  flex-direction: column;

  justify-content: center;
  width: 100%;
  gap: 0px;
  padding: 5px;
  background: var(--color-bg-dark-2);
}
.row.active {
  background: var(--color-bg-dark-2);
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}
.item,
.value {
  text-align: left;
  padding: 12px 5px;
  white-space: nowrap;
  font-size: 13px;
  color: var(--color-grey-text);

  font-weight: 500;
  line-height: 142.857%;
}
.value {
  color: var(--white-color);
  margin-left: auto;
}
.status {
  text-align: right;
}
.pair {
  color: var(--color-primary);
}
.buy {
  color: var(--color-green);
}
.sell {
  color: var(--color-red);
}
.completed {
  color: var(--color-green);
}
.expired {
  color: #ff9e2d;
}
.canceled {
  color: var(--color-red);
}
.item span {
  font-size: 12px;
  opacity: 0.8;
}
.lightTheme .item span {
  opacity: 1;
}
.lightTheme .item span {
  opacity: 1;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.deleteIcon {
  cursor: pointer;
  color: var(--color-grey-text);
  font-size: 18px;
}
.buttonContainer:hover .deleteIcon {
  color: var(--color-red);
}
.arrow {
  cursor: pointer;
  margin-left: auto;
  font-size: 18px;
}
.pagination {
  padding: 0 20px;
  margin-top: auto;
  padding-bottom: 20px;
}

@media only screen and (max-width: 520px) {
  .item {
    font-size: 12px;
  }
}
@media only screen and (max-width: 360px) {
  .row {
    grid-template-columns: repeat(2, 1fr) 70px 24px;
  }
}
