.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container {
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark-3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.lightTheme .container {
  border: 1px solid var(--color-border-dark);
}
.header {
  padding: 0 24px;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
}
.balanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  padding-bottom: 0;
}
.balance {
  color: var(--white-color);

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.usdBalance span {
  color: var(--white-color);
}
.usdBalance {
  color: var(--color-grey-text);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.556%;
  margin-top: 3px;
}
.balanceContainer .button {
  padding: 12px 16px;
  height: auto;
}
.buyAndDepositCrypto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
@media only screen and (max-width: 1024px) {
  .header,
  .balanceContainer {
    padding: 15px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 991px) {
  .buyAndDepositCrypto {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .balance {
    font-size: 26px;
  }
  .usdBalance {
    font-size: 16px;
  }
  .balanceContainer .button {
    font-size: 14px;
    padding: 12px 12px;
    height: auto;
  }
}
