.slick-dots {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.slick-dots li {
  margin: 0 10px;
}

.slick-dots button {
  font-size: 18px;
  color: #333;
  background-color: #2a2b2f !important;
  border: 1px solid transparent;
  border-radius: 50%;
  width: auto;
  height: 30px; /* Set a fixed height for all buttons */
  position: relative;
  transition: all 0.3s ease; /* Add a smooth transition effect */
}

.slick-dots button::before {
  display: none !important;
}

.slick-dots button:hover {
  background-color: #555;
  transform: none;
}

.slick-dots .slick-active button {
  /* Set a larger width for the active button */
  padding: 0 10px; /* Adjust padding if necessary */
  border-radius: 10px;
  width: 40px !important;

  background: var(--color-primary) !important;
}

/* Adjust the spacing between buttons */
.slick-dots .slick-active ~ li {
  margin-left: 30px;
  margin-right: 0;
  /* Increase the margin for the buttons after the active one */
}
