.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 60px 0;
  border-top: 1px solid var(--color-border-dark);
}

.heading {
  color: var(--white-color);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}
.highlight {
  color: var(--color-yellow-text);
}
.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faqContainer {
  border-radius: 12px;
  border-left: 4px solid transparent;
  background: var(--color-bg-dark-3);

  box-shadow: 0px 1px 2px 0px rgba(28, 28, 39, 0.05);
  padding: 20px;
  padding-left: 28px;
}
.activeFaqContainer {
  border-left: 4px solid var(--color-primary);
}
.questionContainer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
}
.activeFaqContainer .questionContainer {
  border-bottom: 1px solid var(--color-bg-dark-2);
  padding-bottom: 15px;
}
.question {
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.icon {
  font-size: 20px;
  min-width: 22px;
  margin-left: auto;
  cursor: pointer;
  margin-top: 6px;
}
.answer {
  color: var(--color-grey-text2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  padding-top: 15px;
}
@media only screen and (max-width: 1199px) {
}
@media only screen and (max-width: 991px) {
  .faqContainer {
    padding: 20px;
    padding-left: 20px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 40px 0;
  }
  .heading {
    font-size: 32px;
  }
}
@media only screen and (max-width: 520px) {
  .faqContainer {
    border-width: 2px;
    padding-left: 15px;
  }

  .question {
    font-size: 18px;
  }
  .icon {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .heading {
    font-size: 30px;
  }
}
