.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 60px 0;
  border-top: 1px solid var(--color-border-dark);
  align-items: center;
}

.img {
  max-width: 350px;
  width: 100%;
  height: auto;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 520px;
  margin: 0 auto;
}

.heading {
  color: var(--white-color);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.highlight {
  color: var(--color-yellow-text);
}

.text {
  color: var(--color-grey-text2);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.downloadFrom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding-top: 28px;
}

.qrCode {
  max-width: 219px;
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.lightTheme .buttonContainer .button {
  border: 1px solid var(--color-border-dark);
}

.label {
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-bottom: 5px;
}

.button h5 {
  font-size: 10px;
}

.button h6 {
  color: var(--white-color);
  text-align: left;
  font-size: 16px;
}

@media only screen and (max-width: 1199px) {
  .heading br {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heading,
  .text {
    text-align: center;
  }

  .downloadFrom {
    justify-content: center;
    gap: 24px;
  }

  .qrCode {
    max-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 40px 0;
  }

  .heading {
    font-size: 32px;
  }

  .text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 450px) {
  .downloadFrom {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 400px) {
  .heading {
    font-size: 30px;
  }
}