.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  padding: 15px 0;
  background: var(--color-bg-dark-3);
  width: 100%;
  position: relative;
  padding: 15px;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 28px;
  width: 100%;
}

.activeButtons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 8px;
  width: 100%;
}
.lightTheme .activeButtons {
  background: var(--color-bg-dark-4);
}
.button {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.button:hover {
  transform: translateY(-3px);
}
.activeButton {
  background: var(--color-bg-dark-2);
}
.activeButton {
  color: var(--white-color);
}
.tabs {
  display: flex;
  align-items: center;

  gap: 15px;
  border-bottom: 1px solid var(--color-border-dark);

  width: 100%;
}

.tab {
  color: var(--color-grey-text2);

  font-size: 14px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
  padding-bottom: 12px;
  cursor: pointer;
  border: 2px solid transparent;
}
.activeTab {
  color: var(--color-primary);

  border-bottom: 2px solid var(--color-primary);
}
.mobileWrapper {
  display: none;
}
@media only screen and (max-width: 1440px) {
  .wrapper {
    gap: 20px;
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 1366px) {
  .mainWrapper {
    padding: 12px;
  }
  .wrapper {
    gap: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 767px) {
  .lightTheme {
    border: none;
  }
  .mainWrapper {
    padding: 0;
  }
  .mobileMainWrapper {
    padding: 15px;
    margin-bottom: 85px;
  }
  .wrapper {
    display: none;
  }
  .activeButtons,
  .tabs {
    display: none;
  }
  .activeButtons.showOnMobile {
    display: grid;
  }
  .tabs.showOnMobile {
    display: flex;
  }
  .mobileWrapper {
    display: block;
  }
}
@media only screen and (max-width: 520px) {
  .mainWrapper {
    padding: 12px;
  }
  .tabs {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 380px) {
  .mainWrapper {
    padding: 10px;
  }
  .button {
    font-size: 13px;
  }
  .tab {
    font-size: 13px;
  }
}
