.wrapper {
  background: var(--color-bg-dark-3);
  padding: 18px 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
}

.lightTheme {
  border: 1px solid var(--color-border-dark);
}
.heading {
  color: var(--white-color);

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.inputAndDropdown {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 15px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.inputAndMax {
  display: grid;
  grid-template-columns: 1fr 22px;
  padding-right: 5px;

  gap: 10px;
}
.max {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: var(--color-green);
  font-size: 16px;

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
@media only screen and (max-width: 520px) {
  .max {
    font-size: 16px;
  }
}
.inputContainer,
.durationContainer {
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  font-size: 16px;
  font-weight: 500;

  width: 100%;
  border-radius: 12px;

  border: none;
  outline: none;
  border: 1px solid var(--color-border-dark);
  transition: 0.4s ease;

  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
}
.durationContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 15px;
  padding: 18px 20px;
}
.dropdownContainer {
  border-left: 3px solid var(--color-border-dark);
  padding-left: 15px;
}
.input {
  width: 100%;
}

.input input {
  font-size: 26px;
  font-weight: 500;
  padding: 0;
  color: var(--white-color);

  width: 100%;
  background: transparent;
  border: none;
  height: auto;
}
.lightTheme .input input {
  box-shadow: none;
}
.input input:focus {
  border: none;
}
.availableBalanceAndApy {
  background: var(--color-bg-dark);
  border-radius: 12px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
}
.balanceContainer {
  border-bottom: 1px solid var(--color-bg-dark-2);
}
.lightTheme .balanceContainer {
  border: none;
}
.text {
  color: var(--color-grey-text2);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.balance {
  margin-left: auto;
  color: var(--white-color);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.currency {
  font-size: 14px;
  color: var(--color-grey-text);
}
.apy {
  color: var(--color-green);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-left: auto;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 12px;
  }
  .inputAndDropdown {
    grid-template-columns: 1fr;
    gap: 22px;
  }
  .dropdownContainer {
    padding: 0;
    border: none;
  }
  .inputContainer .input input {
    font-size: 28px;
  }
  .max {
    font-size: 16px;
  }
  .balance {
    font-size: 16px;
  }
  .inputContainer,
  .durationContainer {
    padding: 12px 12px;
  }
}
