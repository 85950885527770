.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 60px 0;
  border-top: 1px solid var(--color-border-dark);
  align-items: center;
}
.img {
  max-width: 500px;
  width: 100%;
}
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  max-width: 520px;
  margin: 0 auto;
}

.heading {
  color: var(--white-color);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.highlight {
  color: var(--color-yellow-text);
}
.text {
  color: var(--color-grey-text2);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.button {
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .heading br {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .textContainer {
    align-items: center;
  }
  .heading,
  .text {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 40px 0;
  }
  .heading {
    font-size: 32px;
  }
  .text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .heading {
    font-size: 30px;
  }
}
