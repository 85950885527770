.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  padding: 15px;
  background: var(--color-bg-dark-3);

  width: 100%;
  position: relative;
  height: 100%;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}
.inputContainer {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 0px 10px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: var(--color-bg-dark-3);

  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.26),
    0px 1px 1px 0px rgba(0, 0, 0, 0.23);
  width: 100%;
}
.lightTheme .inputContainer {
  border: 1px solid var(--color-border-dark);
  box-shadow: none;
}
.input {
  color: var(--color-grey-text);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 8px;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.searchIcon {
  font-size: 18px;

  cursor: pointer;
  opacity: 0.5;
}
.container {
  width: 100%;
  height: 100%;
}
.valueContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 8px;
  width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.header {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-border-dark);
}
.header,
.valueWrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
}

.heading,
.text {
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text {
  color: var(--white-color);
}

.price {
  display: flex;
  align-items: center;
  gap: 3px;
}
.headingContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}
.lastHeading {
  justify-content: flex-end;
}
.heading {
  color: var(--color-grey-text2);
  font-size: 12px;
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.pairWithContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.pairWith {
  color: var(--color-grey-text2);
  font-size: 10px;
}
.upDown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  opacity: 1;
}
.upArrow {
  color: var(--color-grey-text2);
  font-size: 7px;
}
.downArrow {
  color: var(--color-grey-text2);
  font-size: 7px;
}
.growth,
.new {
  display: block;
  border-radius: 2px;
  background: rgba(75, 115, 255, 0.1);
  padding: 1px 3px;
  color: var(--color-primary);
  font-size: 10px;
}
.new {
  text-transform: uppercase;
}
.positiveArrow {
  color: var(--color-green);
}
.negativeArrow {
  color: var(--color-red);
}
.activeArrow {
  color: var(--color-primary);
}
.star {
  color: var(--color-grey-text2);

  margin-right: 5px;
  cursor: pointer;
}
.favorite {
  color: var(--color-yellow-text);
}
.textRight {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.sellData,
.buyData {
  padding: 10px 0;
}

.green {
  color: var(--color-green);
}
.red {
  color: var(--color-red);
}
.white {
  color: var(--white-color);
}

@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 10px;
  }
  .inputContainer {
    padding: 0px 10px;
  }
  .input {
    padding: 10px 3px;
  }
  .heading,
  .text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 375px) {
  .heading,
  .text {
    font-size: 12px;
  }
  .heading {
    font-size: 14px;
  }
}
