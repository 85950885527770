.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.lightTheme {
}
.wallets {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-bg-dark-2);
}
.lightTheme .wallets {
  border-bottom: 1px solid var(--color-border-dark);
}
.depositAndWithdraw {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.link {
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  white-space: nowrap;
  background: var(--color-bg-dark);
}

.sidebar .link.active {
  background-color: var(--color-bg-dark-2);
  color: var(--white-color);
  font-weight: 500;
}
.lightTheme .link.active {
  background: var(--color-bg-dark-4);
}
.deposit {
  margin-top: 5px;
}
.headingContainer {
  border-bottom: 1px solid var(--color-bg-dark-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lightTheme .headingContainer {
  border-bottom: 1px solid var(--color-border-dark);
}
.heading {
  padding-top: 9px;

  font-weight: 500;

  border-radius: 0;
  font-size: 12px;
}
.arrow,
.close {
  font-size: 22px;
  cursor: pointer;
  display: none;
}
.close {
  top: 30px;
  right: 20px;
  font-size: 24px;
  position: absolute;
  z-index: 7;
}
@media screen and (max-width: 1024px) {
  .sidebar {
    align-items: center;

    width: 100%;
    border-top: 1px solid var(--color-bg-dark-2);
    border-bottom: 1px solid var(--color-bg-dark-2);
    padding: 0 15px;
    padding-top: 60px;
    position: fixed;
    right: 0;
    transform: translateX(150%);
    width: 300px;
    top: 0;
    z-index: 6;
    min-height: 100vh;

    background-color: var(--color-bg-dark);
    z-index: 6;
    transition: 0.4s ease;
  }
  .lightTheme .sidebar {
    border-left: 1px solid var(--color-border-dark);
  }
  .activeSidebar {
    right: 0;
    transform: translateX(0);
  }
  .arrow,
  .close {
    display: block;
  }
  .walletTabs {
    width: 100%;
  }
  .wallet,
  .depositAndWithdraw {
    width: 100%;
  }
  .depositAndWithdraw {
    border-top: 1px solid var(--color-bg-dark-2);
  }
  .lightTheme .depositAndWithdraw {
    border: none;
  }
  .wallets {
    border: 0;
  }
  .link {
    padding: 18px 25px;
  }
  .heading {
    padding: 18px 0;
    font-size: 18px;
  }
}
/* @media screen and (max-width: 1199px) {
  .sidebar {
    grid-template-columns: repeat(2, 1fr);
  }
} */
/* @media screen and (max-width: 991px) {
  .sidebar {
    grid-template-columns: 1fr;
  }
} */
/* @media screen and (max-width: 640px) {
  .wallets {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .depositAndWithdraw {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
} */
