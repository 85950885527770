.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 18px;
  background: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  border-radius: 12px;
  width: 100%;
}

.label {
  font-size: 16px;
  color: var(--white-color);
}
.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 24px;
  padding-right: 15px;
  gap: 10px;
}
.input {
  max-width: 100%;

  padding: 5px 0;
}
.input input {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0;
  font-size: 36px;
}

.max {
  border: none;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: var(--color-green);
  font-size: 18px;

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
@media only screen and (max-width: 520px) {
  .max {
    font-size: 16px;
  }
}
