.dropdown {
  position: relative;

  border-radius: 8px;
}

.dropdownMain {
  height: 0;
  overflow: hidden;
  background-color: var(--base-300);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px #00000014;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;
}

@media screen and (max-width: 446px) {
  .dropdownMain {
    width: calc(100vw - 40px);
  }
}

.dropdownMain.active {
  /* overflow: auto; */
  height: 352px;
  opacity: 1;
  pointer-events: all;
}

.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(8px + 8px);
  transform: translateY(calc(-50%));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 32px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--base-100);
}

.list {
  overflow: auto;
}

.listItem {
  height: auto;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s ease;
}

.listItem .left {
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
}

.listItem .right {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.listItem.active,
.listItem:hover {
  background-color: var(--color-primary);
}
.listItem.active .left,
.listItem.active .right {
  color: var(--white);
}
.listItem:hover .left,
.listItem:hover .right {
  color: var(--white);
}
