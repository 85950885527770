.root {
  border: 0;
  border-top: 1px solid var(--color-border-dark);
  margin: 56px 0;
}

.shortMargin {
  margin: 32px 0;
}

.mdShortMargin {
  margin: 24px 0;
}

.xShortMargin {
  margin: 12px 0;
}

.noMargin {
  margin: 0;
}
