.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 26px;
  flex-shrink: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--base-300);
  border: 1px solid var(--color-border-dark);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.lightTheme .slider {
  background-color: var(--dark);
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  top: 50%;
  box-shadow: 0px 2px 2px 0px #0000000f, 0px 1px 1px 0px #00000008;
  transform: translateY(-50%);
  background-color: var(--white-color);
  opacity: 0.2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--color-primary);
}

.lightTheme input:checked + .slider {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

input:disabled + .slider {
  opacity: 0.5;
  pointer-events: none;
}

input:checked + .slider::before {
  opacity: 1;
  background: var(--white);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translate(18px, -50%);
  -ms-transform: translate(18px, -50%);
  transform: translate(18px, -50%);
}

/* Rounded sliders */
.slider.round {
}

.slider.round:before {
}
