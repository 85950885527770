.wrapper {
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark-3);
  border-radius: 8px;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
  padding: 24px;
}
@media only screen and (max-width: 1024px) {
  .header {
    padding: 15px;
  }
}
