.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.item {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
}

.item {
  color: var(--white-color);

  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
}
.item span {
  font-size: 12px;
  opacity: 0.8;
}

.logoAndNameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.name {
  font-weight: 500;
  color: var(--white-color);
}
.nameContainer span {
  font-size: 12px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.moreContainer {
  background: var(--color-bg-dark-3);
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}
.pagination {
  padding: 0 20px;
  margin-top: auto;
  padding-bottom: 20px;
}

@media only screen and (max-width: 520px) {
  .logoAndNameContainer {
    gap: 8px;
  }
  .logo {
    width: 27px;
    height: 27px;
  }
  .item {
    font-size: 12px;
  }
}
