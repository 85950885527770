.wrapper {
  max-width: 1800px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 15px;
}
.dekstopView {
  display: grid;
  grid-template-columns: 353px 1fr;
  gap: 8px;
  padding: 20px 0;
  width: 100%;
}
.right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.chartAndPairs {
  display: grid;
  grid-template-columns: 1fr 353px;
  gap: 8px;
  height: 100%;
}
.buySellAndTrading {
  display: grid;
  grid-template-columns: 1fr 353px;
  gap: 8px;
}
/* modbile styles start */

.mobileComponents {
  background: var(--color-bg-dark-2);
  padding: 4px;
  border-radius: 6px;
  display: none;
}
.tabs {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 3px;
  border-radius: 8px;
  margin: 0 auto;
}
.lightTheme .tabs {
  background: var(--color-bg-dark-4);
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}

.line {
  border-bottom: 1px solid var(--color-border-dark);
  margin: 15px 0;
}
/* .middle {
  display: flex;
  flex-direction: column;
  gap: 18px;
} */

.tablet {
  display: none;
}
@media only screen and (max-width: 1440px) {
  .dekstopView {
    grid-template-columns: 300px 1fr;
  }
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    gap: 18px;
  }
  .dekstopView {
    display: none;
  }
  .mobileComponents {
    display: flex;
    flex-direction: column;
  }
  .tablet {
    display: block;
  }
}
@media only screen and (max-width: 520px) {
  .tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    max-width: 300px;
    width: 100%;
  }
  .tab {
    font-size: 13px;
    padding: 8px 10px;
    text-align: center;
  }
}
@media only screen and (max-width: 380px) {
  .tab {
    font-size: 13px;
  }
}
