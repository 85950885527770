.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.item {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
}

.item {
  color: var(--color-grey-text);

  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
}
.lastItem {
  text-align: right;
}
.nameContainer p,
.amount {
  color: var(--whtie-color);
}
.live {
  color: #ff9e2d;
}
.finished {
  color: var(--color-green);
}
.stakeId {
  display: flex;
  align-items: center;
  gap: 10px;
}
.copyIcon {
  max-width: 18px;
  opacity: 0.6;
  cursor: pointer;
}
.copyIcon:hover {
  opacity: 1;
}

.negativeAmount {
  color: var(--color-red);
}
.positiveAmount {
  color: var(--color-green);
}
.logoAndNameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.nameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}
.nameContainer p {
  font-weight: 500;
  color: var(--white-color);
}
.nameContainer span {
  font-size: 12px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
}
.pagination {
  padding: 0 20px;
  margin-top: auto;
  padding-bottom: 20px;
}
@media only screen and (max-width: 520px) {
  .logoAndNameContainer {
    gap: 8px;
  }
  .logo {
    width: 27px;
    height: 27px;
  }
  .item {
    font-size: 12px;
  }
}
/* Firefox */
.marketTable {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) rgba(43, 58, 80, 0.65);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.marketTable::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 5px;
}

.marketTable::-webkit-scrollbar-track {
  background: var(
    --Radial,
    radial-gradient(
      99.99% 99.99% at 50% 0.01%,
      rgba(43, 58, 80, 0.65) 0%,
      rgba(23, 33, 48, 0.8) 100%
    )
  );
  border-radius: 5px;
}

.marketTable::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
