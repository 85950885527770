.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  width: 90%;
  max-width: 480px;
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.inputContainer {
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  font-size: 16px;
  font-weight: 500;

  width: 100%;
  border-radius: 8px;

  border: none;
  outline: none;
  border: 1px solid var(--color-border-light);
  transition: 0.4s ease;

  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
}
.lightTheme .inputContainer {
  border: 1px solid var(--color-border-dark);
}
.arrowContainer {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-bg-dark);
  border: 4px solid var(--color-bg-dark-2);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -80%);
}
.lightTheme .arrowContainer {
  border: 1px solid var(--color-border-dark);
}
.toArrow {
  font-size: 18px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

.label {
  color: var(--white-color);
}
.balance {
  margin-left: auto;
}
.inputAndDropdown {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 15px;
}
.dropdownContainer {
  min-width: 120px;
  border-left: 3px solid var(--color-border-dark);
  padding-left: 15px;
}

.input {
  width: 100%;
}

.input input {
  font-size: 36px;
  font-weight: 500;
  padding: 0;
  color: var(--white-color);

  width: 100%;
  background: transparent;
  border: none;
  height: auto;
}
.lightTheme .input input {
  box-shadow: none;
}
.input input:focus {
  border: none;
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.conversion {
  font-weight: 500;
  line-height: 24px;
  color: var(--white-color);
  margin-left: auto;
}
@media only screen and (max-width: 520px) {
  .inputAndDropdown {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
