.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 500px;
  width: 90%;
}
.heading {
  font-size: 16px;
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding-top: 15px;
}
.infoContainer {
  border: 1px solid var(--color-border-dark);
  border-radius: 12px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.label {
  color: var(--color-grey-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.value {
  margin-left: auto;
  color: var(--white-color);
}

.refer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px;
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark-3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
}
.title {
  font-size: 20px;
  color: var(--white-color);
}
.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.linkContainer {
  background: var(--color-bg-dark);
  padding: 10px 8px;
  border-radius: 6px;
  width: 100%;
}
.lightTheme .linkContainer {
  background: var(--color-bg-dark-4);
}
.link {
  overflow: hidden;
  color: var(--color-grey-text);
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.copyContainer {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: var(--color-border-dark);
  border-radius: 6px;
  width: 40px;
  padding: 11px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.lightTheme .copyContainer {
  background: var(--color-bg-dark-4);
}
.copyContainer:hover {
  transform: translateY(-3px);
}
.copyIcon {
  font-size: 18px;
}
.lightTheme .copyIcon {
  color: #000;
}
.earningFrom {
  color: var(--color-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
@media only screen and (max-width: 1199px) {
  .link {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 520px) {
  .refer {
    padding: 12px;
  }
  .title {
    font-size: 18px;
  }
  .link {
    font-size: 14px;
  }
}
@media only screen and (max-width: 520px) {
  .label,
  .value {
    font-size: 14px;
  }
}
@media only screen and (max-width: 450px) {
  .link {
    max-width: 200px;
    white-space: nowrap;
  }
  .earningFrom {
    font-size: 13px;
  }
}
