.viewMore {
  font-size: 14px;
  color: var(--color-grey-text2);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.1px;
  padding: 10px 14px;
  padding-right: 0;
  border-radius: 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.right {
  fill: var(--color-grey-text2);
  font-size: 18px;
}
