@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
:root {
  --white: #fff;
  --black: #222327;
}
.dark-theme {
  --color-bg-dark: #17181c;
  --color-bg-dark-2: #222327;
  --color-bg-dark-3: #1d1e21;
  --color-border-dark: #2a2b2f;
  --color-border-dark-2: #222327;
  --color-border-dark-3: #1d1e21;
  --color-border-light: #ffffff0d;
  --color-grey-text: #c2c4c4;
  --color-grey-text2: #8a8c8d;
  --color-grey-text-light: #8a8c8d;
  --color-yellow-text: #f8d33a;
  --color-primary: #4b73ff;
  --color-red: #fa4b55;
  --color-green: #11d080;
  --color-border-light: #ffffff0d;
  --color-red-light: #fa4b551a;
  --color-green-light: #17de8a1a;
  --color-yellow-light: rgba(255, 158, 45, 0.1);
  --color-primary-light: rgba(75, 115, 255, 0.1);

  --box-shadow-main: 0px 2px 2px 0px #0000000f, 0px 1px 1px 0px #00000008;
  --white-color: #fff;
  --shadowColor: #00000014;

  /* new */
  --base-300: #222327;
  --base-400: #1d1e21;
  --base-200: #2a2b2f;

  --base-50: #c2c4c4;
  --base-100: #8a8c8d;
}

.light-theme {
  --color-bg-dark: #fff;
  --color-bg-dark-2: #fff;
  --color-bg-dark-3: #fff;
  --base-300: #fff;
  --base-400: #fff;
  --base-200: #fff;
  --color-bg-dark-4: #f4f4f6;
  --color-bg-dark-5: #fcfcfe;

  --color-bg-dark-6: #404040;
  --color-border-dark: #e7e7e9;
  --color-border-dark-3: #e7e7e9;
  --color-border-dark-2: #e7e7e9;
  --color-border-light: #ffffff0d;
  --color-grey-text: #332f2f;
  --base-50: #332f2f;
  --color-grey-text2: #332f2f;
  --base-100: #332f2f;
  --color-grey-text-light: #332f2f;
  --color-yellow-text: #fbca06;
  --color-primary: #4b73ff;
  --color-red: #fa4b55;
  --color-green: #11d080;
  --color-red-light: #fa4b551a;
  --color-green-light: #17de8a1a;
  --color-yellow-light: rgba(255, 158, 45, 0.1);
  --color-primary-light: rgba(75, 115, 255, 0.1);
  --box-shadow-main: 0px 4px 6px rgba(0, 0, 0, 0.1);
  --white-color: #000;
  --shadowColor: #00000029;
}
.light-theme,
.dark-theme {
  min-height: 100vh;
}
* {
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-variation-settings: "slnt" 0;

  margin: 0;
  padding: 0;
}
.klinecharts-pro-modal {
  top: 25px !important;
}

.dark-theme {
  background-color: #17181c;
  white-space: normal;
}
.light-theme {
  background-color: #fff;
  white-space: normal;
}
button {
  transition: 0.3s;
}
button:hover {
  transform: translateY(-3px);
}

h2,
h4 {
  color: var(--white-color);
}
a,
input,
div,
button,
span {
  color: var(--color-grey-text);
}
/* .light-theme a {
  color: var(--white-color);
} */
h2 {
  font-size: 24px;
  font-weight: 400;
  color: var(--color-grey-text);
}
svg {
  color: var(--color-grey-text);
}
h4 {
  font-size: 36px;
  font-weight: 500;
  color: var(--color-grey-text);
}

h6 {
  font-size: 20px;
  font-weight: 400;
  color: var(--color-grey-text);
}
p {
  color: var(--color-grey-text);
}

.container {
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
  padding: 40px 0;
}
.marginTop {
  padding-top: 80px;
}
@media only screen and (max-width: 1024px) {
  .container {
    width: 90%;
  }
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-bg-dark);
  border-radius: 4px;
}
.overflow {
  scrollbar-width: thin;
  scrollbar-color: #4b73ff rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari browsers */
.overflow::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.overflow::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
  border-radius: 5px;
}

.overflow::-webkit-scrollbar-thumb {
  background: #4b73ff;
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
