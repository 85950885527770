/* .balanceContainer {
  border: 1px solid var(--color-bg-dark-2);
  padding: 24px;
  background: var(--color-bg-dark-3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
} */
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
.heading {
  color: var(--white-color);

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.tabs {
  display: flex;
  align-items: center;
  gap: 25px;
}
.tab {
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.amountContainer {
  display: grid;
  grid-template-columns:
    1fr
    auto;
  gap: 20px;
  align-items: center;
  border: 1px solid var(--color-border-dark);
  padding: 12px;
  border-radius: 15px;
}
.amount {
  font-size: 30px;
  text-align: center;
}
.dropdownWrapper {
  border-left: 1px solid var(--color-border-dark);
}
.equvalentBalance {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.usdBalance {
  color: var(--color-grey-text);
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}
@media only screen and (max-width: 640px) {
  .heading {
    font-size: 22px;
  }
  .tabs {
    gap: 13px;
  }
  .tab {
    font-size: 14px;
  }
  .amount {
    font-size: 24px;
  }
  .buttonContainer .button {
    padding: 12px 14px;
    height: auto;
    font-size: 14px;
  }
}
