.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 18px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
}
