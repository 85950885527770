.sectionWrapper {
  padding-left: 20px;
  padding-right: 20px;
}

.section {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.section.md {
  max-width: 1200px;
}

.section.short {
  max-width: 996px;
}

.section.xShort {
  max-width: 448px;
}

.sectionWrapper.withPadding .section {
  padding-top: 56px;
  padding-bottom: 56px;
}

.sectionWrapper.withPaddingShort .section {
  padding-top: 40px;
  padding-bottom: 40px;
}
