.text {
  text-align: center;
}

.text .title {
  margin-bottom: 12px;
}

.inputContainer {
  margin: 32px 0 12px;
}

.inputContainer label {
  display: block;
  margin-bottom: 6px;
  color: var(--base-100);
  font-weight: 500;
  font-size: 14px;
}

.ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--base-100);
  margin-bottom: 4px;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
