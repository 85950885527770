.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border-dark-2);
}
.inputWrapper,
.dateOfBirth {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
}
.inputContainer .input {
  max-width: 100%;
}
@media only screen and (max-width: 520px) {
  .inputContainer {
    grid-template-columns: 1fr;
  }
}
