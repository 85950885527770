.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 18px;
  background: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  border-radius: 12px;
  width: 100%;
}
.dropdownContainer {
  width: 100%;
}
.label {
  font-size: 16px;
  color: var(--white-color);
}
.input {
  border-top: 2px solid var(--color-border-dark);
  max-width: 100%;
  border-bottom: 2px solid var(--color-border-dark);
  padding: 5px 0;
}
.input input {
  background: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0;
}
