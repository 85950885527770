.heading {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 500;
}
.center {
  text-align: center;
}
.lg {
  font-size: 24px;
}
.wFull {
  width: 100%;
}
.border {
  border-bottom: 1px solid var(--color-bg-dark-2);
  padding-bottom: 16px;
}
.lightTheme.border {
  border-bottom: 1px solid var(--color-border-dark);
}
.success {
  color: var(--color-green);
}
