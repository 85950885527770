.wrapper {
  max-width: 460px;
  width: 95%;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 25px;
}
.text {
  text-align: center;
  color: var(--color-grey-text2);
}
.wrapper .inputContainer {
  width: 100%;
}
.lightTheme .inputContainer .input {
  background: var(--color-bg-dark-4);
  border: 1px solid var(--color-border-dark);
  box-shadow: none;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}
