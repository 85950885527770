.wrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.container {
  display: grid;
  grid-template-columns: 270px 1fr;
  gap: 24px;
  background: var(--color-bg-dark-3);

  border-radius: 6px;
  padding-bottom: 0px;
  padding-top: 15px;
  padding-left: 15px;
}
.lightTheme .container {
  padding-left: 0;
}
@media only screen and (max-width: 1199px) {
  .container {
    display: flex;
    flex-direction: column;
  }
  .container {
    padding-left: 0;
  }
}
