.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}
.lightTheme .wrapper {
  background: var(--white);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.label,
.value {
  color: var(--color-grey-text2);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
  letter-spacing: -0.28px;
}
.value {
  margin-left: auto;
  color: var(--white-color);
}

.inputContainer {
  border-radius: 6px;
  border: 1px solid var(--color-border-dar);
  background: var(--color-bg-dark-2);
  padding: 11px 15px;
  width: 100%;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lightTheme .inputContainer {
  background: var(--color-bg-dark-4);
}

.input {
  overflow: hidden;
  color: var(--white-color);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
}

.currencyName {
  color: var(--color-grey-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.32px;
  border-left: 1px solid var(--color-border-dark);
  padding-left: 10px;
  min-width: 80px;
  text-align: right;
}

.percentages {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  border-radius: 12px;

  padding: 8px;
}
.percentage {
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.077%;
  padding: 10px 10px;
  border-radius: 6px;
  cursor: pointer;
  background: var(--color-bg-dark-2);
}
.activePercentage {
  color: var(--white);
  background: var(--color-primary);
}
.infoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.button {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
  border-radius: 10px;
  background: var(--color-green);
  width: 100%;
  padding: 10px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.26),
    0px 1px 1px 0px rgba(0, 0, 0, 0.23);
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cancelButton {
  display: none;
}
@media only screen and (max-width: 1366px) {
  .tabs {
    gap: 12px;
  }
  .tab {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1199px) {
  .tabs {
    gap: 25px;
  }
  .tab {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .tabs {
    gap: 15px;
  }
  .tab {
    font-size: 13px;
  }
}
@media only screen and (max-width: 520px) {
  .tabs {
    gap: 12px;
  }
  .tab {
    font-size: 12px;
  }
  .inputContainer {
    padding: 8px 12px;
    gap: 8px;
  }
  .currencyName {
    padding-right: 8px;
    font-size: 15px;
  }

  .all,
  .bestPrice {
    font-size: 13px;
  }
  .button {
    padding: 15px 20px;
  }
  .cancelButton {
    display: block;
  }
}
@media only screen and (max-width: 380px) {
  .tabs {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
