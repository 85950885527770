.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-bg-dark-2);
}

.container {
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark-3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}
.lightTheme .container {
  border: 1px solid var(--color-border-dark);
}
.header {
  padding: 24px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}
.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  flex-wrap: wrap;
}
.tabs {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 6px;
  margin: 15px 0;
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
}
.buyAndDepositCrypto {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}
@media only screen and (max-width: 1024px) {
  .header {
    padding: 15px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 991px) {
  .buyAndDepositCrypto {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
