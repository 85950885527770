.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 588px;
  margin: 0 auto;
}

.heading {
  color: var(--white-color);

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}
.highlight {
  color: var(--color-yellow-text);
}
.text {
  text-align: center;
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.buttonContainer .button {
  padding: 12px 24px;
}
.imgContainer {
  max-width: 991px;
  width: 100%;
  margin: 0 auto;
  margin-top: 32px;
}
.img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding-bottom: 40px;
  }
  .heading {
    font-size: 40px;
  }
  .text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .heading {
    font-size: 36px;
  }

  .buttonContainer .button {
    font-size: 14px;
    padding: 10px 20px;
  }
}
