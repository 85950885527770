.phoneNumber {
  display: flex;
  gap: 8px;
}

.dropdownItem {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  color: var(--white-color);
  width: 116px;
  background-color: var(--base-400);

  box-shadow: var(--box-shadow-main);
  border-radius: 8px;
  height: 48px;
  cursor: pointer;
}

.dropdownItem .icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
