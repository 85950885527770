.modalOverlay {
  background-color: var(--color-bg-dark);
  backdrop-filter: blur(4px);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 6;
  transition: 0.3s ease-in-out;
  pointer-events: none;
}

.modal {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
  z-index: 6;
  max-width: 95%;

  /* my */
  margin: 0 auto;

  overflow-y: auto;
}

.modalOverlay.active {
  opacity: 1;
  pointer-events: all;
}
.lightTheme.active {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.active {
  pointer-events: all;
  opacity: 1;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .modal {
    overflow-y: auto;
  }
}
@media only screen and (max-width: 520px) {
  .modal {
    overflow-y: auto;
  }
}
