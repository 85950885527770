.form {
  margin: 56px 0;
}

.form .btn {
  margin-top: 16px;
  width: 100%;
  justify-content: center;
}

.form .input {
  max-width: unset;
}
