.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 500px;
  width: 90%;
}

.logoAndNameContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.logo {
  max-width: 32px;
  height: 32px;
  border-radius: 50%;
}

.name {
  font-weight: 500;
  color: var(--white-color);
}
.nameContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.nameContainer span {
  font-size: 12px;
}
.balanceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid var(--color-border-dark);
  border-radius: 12px;
  padding: 18px;
}
.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.balance h3 {
  color: var(--white-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 20%;
}
.balance p {
  font-size: 18px;
}
.balance p span {
  color: var(--white-color);
}
.infoContainer {
  border: 1px solid var(--color-border-dark);
  border-radius: 12px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}

.value {
  margin-left: auto;
  color: var(--white-color);
}
.boxWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.infoContainer .logoAndNameContainer {
  gap: 5px;
}
.infoContainer .logo {
  width: 24px;
}
.infoContainer .name {
  font-size: 14px;
}
.infoContainer .text {
  color: var(--white-color);
  white-space: nowrap;
}
.growth {
  color: var(--color-green);
  margin-left: auto;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  border: 1px solid var(--color-border-dark);
  border-radius: 12px;
  padding: 18px;
}
@media only screen and (max-width: 520px) {
  .boxWrapper {
    grid-template-columns: 1fr;
  }
  .balance h2 {
    font-size: 22px;
  }
  .balance p {
    font-size: 16px;
  }
  .label,
  .value {
    font-size: 14px;
  }
}
