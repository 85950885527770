.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.item {
  text-align: left;
  padding: 12px 24px;
  white-space: nowrap;
  font-size: 13px;
}

.item {
  color: var(--color-grey-text);

  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
}
.buy {
  color: var(--color-green);
}
.sell {
  color: var(--color-red);
}
.pair {
  color: var(--color-primary);
}
.completed {
  color: var(--color-green);
}
.expired {
  color: #ff9e2d;
}
.canceled {
  color: var(--color-red);
}
.item span {
  font-size: 12px;
  opacity: 0.8;
}
.lightTheme .item span {
  opacity: 1;
}
.lastItem {
  text-align: right;
}

@media only screen and (max-width: 520px) {
  .item {
    font-size: 12px;
  }
}
