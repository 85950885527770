.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;

  gap: 15px 25px;
  padding-bottom: 20px;
  border-radius: 15px;
  padding: 20px 16px;
}

.lightTheme {
  border: 1px solid var(--color-border-dark);

  background-color: #404040;

  p,
  span,
  .copy,
  .arrow {
    color: #fff;
    /* Replace with your desired color code or color name */
  }
}

.userContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 250px;
  width: 100%;
}

.user {
  max-width: 64px;
  display: block;
  object-fit: contain;
  object-position: center;
  border: 6px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.userTitle {
  color: var(--white-color);
  font-weight: 500;
}

.id {
  display: flex;
  align-items: center;
  gap: 5px;
}

.copy {
  cursor: pointer;
}

.arrow {
  margin-left: auto;
  cursor: pointer;
  font-size: 18px;
}

.heading {
  color: var(--color-grey-text2);
}

.userContainer:hover .arrow {
  color: var(--white-color);
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 35px 45px;
  width: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.box .info {
  font-size: 16px;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 8px;
}

.verified {
  color: #17de8a;
  display: flex;
  align-items: center;
  gap: 5px;
}

.unverified {
  color: #a11934;
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .userContainer {
    grid-column: 1/1;
  }

  .container {
    display: none;
    border-top: 1px solid var(--color-border-light);
    padding-top: 18px;
  }

  .showAll {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px 15px;
  }
}

@media only screen and (max-width: 640px) {
  .wrapper {
    padding: 20px 10px;
  }

  .showAll {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .heading {
    font-size: 14px;
  }

  .info {
    font-size: 12px;
  }
}
