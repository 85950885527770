.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  min-height: calc(100vh - 250px);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.text {
  text-align: center;
  color: var(--color-grey-text2);
}

.btn {
  margin-left: auto;
  margin-right: auto;
}

.wrapper img.changed {
  width: 100%;
  max-width: 96px;
  display: block;
  margin: 0 auto;
}
