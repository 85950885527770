.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
}

.box {
  padding: 18px;
  background: var(--color-bg-dark-3);
  border: 1px solid var(--color-bg-dark-2);
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
}
.lightTheme .box {
  border: 1px solid var(--color-border-dark);
  border-radius: 6px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  width: 100%;

  border-bottom: 1px solid var(--color-border-dark);
  padding-bottom: 10px;
}
.header h6 {
  color: var(--white-color);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.viewMore {
  font-size: 14px;
  color: var(--color-grey-text2);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.1px;
  padding: 10px 14px;

  border-radius: 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 0;
}
.right {
  fill: var(--color-grey-text2);
  font-size: 20px;
}
.coins {
  display: flex;
  flex-direction: column;
}
.lists {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  gap: 15px;
  transition: 0.3s;
  padding: 14px 0;
  border-radius: 6px;
}
.lists:hover {
  background: var(--color-bg-dark-2);
}
.item {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
}

.negativeAmount {
  color: var(--color-red);
}
.positiveAmount {
  color: var(--color-green);
}
.logoAndNameContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.logo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.logoAndNameContainer p {
  font-weight: 500;
  font-size: 14px;
  color: var(--white-color);
}

.price {
  text-align: right;
}
.change24h {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .box:last-child {
    grid-column: 1/-1;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
