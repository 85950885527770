.accountSettingsLayout {
  display: flex;
}

.accountSettingsLayout .layoutLeft {
  width: 200px;
  flex-shrink: 0;
}

.accountSettingsLayout .layoutRight {
  flex-grow: 1;
  padding: 0 48px;
}

.accountSettingsLayout .layoutRight .title {
  font-size: 20px;
  font-weight: 500;
  color: var(--white-color);
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border-dark-3);
}

.accountSettingsLayout .layoutRight .title .dropdownArrow {
  display: none;
}

.accountSettingsLayout .close {
  display: none;
  font-size: 36px;
  text-align: right;
  margin-right: 0;
  font-weight: 500;
  cursor: pointer;
}

.accountSettingsLayout .layoutRight .layoutMain {
  margin-top: 32px;
}

@media screen and (min-width: 992px) {
  .accountSettingsLayout .layoutLeft .dropdown,
  .accountSettingsLayout .layoutLeft .arrow {
    display: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 991px) {
  .accountSettingsLayout .layoutLeft .dropdown {
    padding: 0 20px;
  }

  .accountSettingsLayout .close {
    display: block;
  }

  .accountSettingsLayout .layoutLeft {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 100vw;
    background-color: var(--base-400);
    height: 100vh;
    padding: 16px;
    z-index: 6;
    overflow: auto;
    transition: 0.4s ease;
  }

  .accountSettingsLayout .layoutLeft.active {
    left: 0;
  }
  /* 
  .accountSettingsLayout .layoutLeft h6 {
    display: none;
  }

  .accountSettingsLayout .layoutLeft hr.lg {
    display: none;
  } */

  .accountSettingsLayout .layoutRight .title .dropdownArrow {
    display: block;
    width: 20px;
    cursor: pointer;
  }

  .accountSettingsLayout .layoutRight {
    padding: 0 20px;
  }
}

@media screen and (max-width: 575px) {
  .accountSettingsLayout .layoutRight {
    padding: 0;
  }
}

.accountSettingsLayout .subTitle {
  padding: 8px 12px;
  color: var(--base-100);
  font-size: 12px;
  font-weight: 500;
}

.navItems {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.navItems .navItem {
  background-color: transparent;
  border: 0;
}

.navItems .navItem .navItemMain {
  height: 44px;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 2px 0px #0000000d;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease;
}

.navItems .navItem .navItemLeft {
  gap: 12px;
  display: flex;
  align-items: center;
}
.lightTheme .navItems .navItem .navItemMain.active .navItemLeft {
  color: var(--white);
}

.navItems .navItem.danger .navItemMain {
  background-color: var(--color-red-light);
}

.navItems .navItem.danger .navItemMain * {
  color: var(--color-red);
}

.navItems .navItem .navItemMain,
.navItems .navItem .navItemMain svg * {
  color: var(--base-100);
}

.navItems .navItem .navItemMain.active {
  background-color: var(--base-300);
  color: var(--white-color);
  font-weight: 500;
}
.lightTheme .navItems .navItem .navItemMain.active {
  background-color: var(--black);
}
.navItems .navItem .navItemMain.active,
.navItems .navItem .navItemMain.active svg * {
  color: var(--white-color);
}
.lightTheme .navItems .navItem .navItemMain.active,
.lightTheme .navItems .navItem .navItemMain.active svg * {
  color: var(--white);
}
@media screen and (min-width: 992px) {
  .navItems .navItem.sm {
    display: none;
  }
}
