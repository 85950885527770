.wrapper {
  max-width: 460px;
  width: 95%;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 25px;
}
.text {
  text-align: center;
  color: var(--color-grey-text2);
}

.btn {
  max-width: 144px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper img.changed {
  width: 100%;
  max-width: 96px;
  display: block;
  margin: 0 auto;
}
.amountContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.amount {
  font-size: 36px;
  color: var(--white-color);
}
.label {
  color: var(--color-grey-text);
  font-size: 18px;
}
.currency {
  font-size: 20px;
  color: var(--color-grey-text);
}
.infoContainer {
  border-top: 1px solid var(--color-border-dark);
  border-bottom: 1px solid var(--color-border-dark);
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}
.infoContainer .label {
  font-size: 16px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}

.value {
  margin-left: auto;
  color: var(--white-color);
}
.apy {
  color: var(--color-green);
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
