.datePickerRoot {
  position: relative;
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  border: 1px solid var(--color-border-light);
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  width: 100%;
  border-radius: 8px;

  transition: 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
}

.datePickerRoot input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;

  display: flex;
  justify-content: space-between;
}
.inputContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.calender {
  color: var(--color-grey-text2);
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .datePickerRoot {
    width: 100%;
  }

  .datePickerRoot input {
    width: 100%;
  }
}

.datePickerRoot input:placeholder {
  color: #c2c2c2;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.datePickerRoot img {
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  cursor: pointer;
}
