.dropdownWrap {
  position: relative;
}
.dropdownWrap:not(.noDesign) * {
  font-size: 16px;
}
.dropdownWrap:not(.noDesign) .arrow {
  font-size: 24px;
}
.dropdownWrap:not(.noDesign) .logo {
  width: 30px;
}
.dropdownWrap:not(.noDesign) .logo.small {
  width: 20px;
}
.dropdownWrap:not(.noDesign) .dropdown {
  padding: 24px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  cursor: pointer;
  height: 70px;
}
.dropdownWrap.borderNone .dropdown {
  padding: 0;
  padding-bottom: 6px;
  border: none;
  border-radius: 0;
  height: auto;
}
.dropdownWrap.noDesign {
  cursor: pointer;
  min-width: 140px;
}
.dropdownWrap.noDesign .option * {
  font-size: 13px;
}
.dropdownWrap.noDesign .option img {
  width: 32px;
}

.dropdownWrap .options {
  position: absolute;
  width: 100%;
  top: calc(100% + 6px);
  border-radius: 10px;
  background-color: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  z-index: 2;
  overflow: hidden;

  max-height: 200px;
  overflow-y: auto;
}
.dropdownWrap .options .option {
  padding: 12px 18px;
  cursor: pointer;
  transition: 0.4s ease;
}
.dropdownWrap .options .option:hover {
  background-color: var(--color-border-dark);
}
.lightTheme .options .option:hover {
  background-color: var(--color-bg-dark-4);
}
.iconContainer {
  display: flex;
  gap: 8px;
}
.alignItemsStart {
  align-items: start;
}
.alignItemsCenter {
  align-items: center;
}
.nameContainer {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 2px;
}
.name {
  color: var(--white-color);
}
.nameContainer .logo {
  width: 24px;
}
.subTitle {
  margin-top: 4px;
  color: var(--white-color);
}

.bold {
  font-weight: 700;
}
.bold .title {
  color: var(--white-color);
}
.arrow {
  font-size: 24px;
}
.dropdownWrap .dropdown .placeHolder {
  color: var(--white-color);
}
@media only screen and (max-width: 767px) {
  .dropdownWrap:not(.noDesign) .dropdown {
    height: 60px;
  }
  .dropdownWrap.borderNone .dropdown {
    height: auto;
  }
}
@media only screen and (max-width: 575px) {
  .iconContainer .logo {
    width: 40px;
  }
  .nameContainer .name {
    font-size: 16px;
  }
}
@media only screen and (max-width: 380px) {
  .dropdownWrap:not(.noDesign) .dropdown {
    padding: 20px 15px;

    height: 50px;
  }
  .dropdownWrap.borderNone .dropdown {
    padding: 0;
    padding-bottom: 6px;

    height: auto;
  }
  .iconContainer .logo {
    width: 36px;
  }
  .nameContainer .name {
    font-size: 14px;
  }
  .subTitle {
    font-size: 14px;
    margin-top: 0px;
  }
}
/* scrollbar css */
/* ===== Scrollbar CSS ===== */
/* Firefox */
.dropdownWrap .options {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) rgba(250, 250, 250, 0.2);
}

/* Chrome, Edge, and Safari */
.dropdownWrap .options::-webkit-scrollbar {
  width: 5px;
}

.dropdownWrap .options::-webkit-scrollbar-track {
  background: rgba(250, 250, 250, 0.2);
}

.dropdownWrap .options::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 100vh;
  border: none;
}
/* searchbar */
.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}
