.dropdown {
  position: relative;
}

.dropdownMain {
  height: 0;
  overflow: hidden;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  transition: 0.4s ease;
}

@media screen and (max-width: 446px) {
  .dropdownMain {
    width: calc(100vw - 40px);
  }
}

.dropdownMain.active {
  /* overflow: auto; */
  height: 352px;
  opacity: 1;
  pointer-events: all;
}

.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--color-grey-text2);
}

.list {
  overflow: auto;
}

.listItem {
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.4s ease;
}

.listItem .left {
  font-size: 16px;
  font-weight: 400;
}

.listItem .right {
  font-size: 14px;
  font-weight: 500;
}

.listItem.active,
.listItem:hover {
  background-color: var(--color-primary);
  color: var(--white-color);
}
.lightTheme .listItem.active .left,
.lightTheme .listItem.active .right,
.lightTheme .listItem:hover .left,
.lightTheme .listItem:hover .right {
  color: var(--white);
}
