.wrapper {
  display: grid;
  grid-template-columns: 1fr 444px;
  gap: 24px;

  align-items: center;
  padding-bottom: 60px;
  border-bottom: 1px solid var(--color-border-dark);
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  max-width: 520px;
}

.heading {
  color: var(--white-color);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.highlight {
  color: var(--color-yellow-text);
}
.text {
  color: var(--color-grey-text);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.stepsContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  padding-top: 15px;
}

.stepsWithArrow {
  display: flex;

  gap: 10px;
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-width: 80px;
}
.icon {
  max-width: 28px;
  width: 100%;
}
.info {
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.33%;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    grid-template-columns: 1fr 400px;
  }
  .heading br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .stepsContainer {
    display: grid;
    grid-template-columns:
      auto
      auto;
    gap: 20px 20px;
  }
  .arrow2 {
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .textContainer {
    align-items: center;
    margin: 0 auto;
  }
  .heading,
  .text {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 40px 0;
  }
  .heading {
    font-size: 32px;
  }
  .text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .heading {
    font-size: 30px;
  }
}
