.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border-dark-2);
}
.nidCardContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.nidCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.uploader {
  background-color: var(--color-bg-dark-3);
  border-radius: 8px;
  border: 1px solid var(--color-border-dark);
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.uploader .upload {
  padding: 24px;
  border-radius: 6px;

  gap: 10px;
  color: var(--color-grey-text-2);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  display: block;
}

.uploader input {
  display: none;
}
.uploadedImg {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  object-fit: center;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 520px) {
  .nidCard {
    grid-template-columns: 1fr;
  }
}
