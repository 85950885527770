.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;

  padding: 25px 18px;
  background: var(--color-bg-dark-3);
  border-radius: 8px;
}

.currencyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
  padding-right: 15px;
}
.currencyContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.logo {
  max-width: 28px;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.currencyName {
  color: var(--white-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.infoIcon {
  font-size: 18px;
}
.arrow {
  cursor: pointer;
  display: none;
}
.itemsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 32px;
  border-left: 1px solid var(--color-border-dark);
}
.text {
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 15px;
}

.green {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-green);
}
.highlighted {
  font-weight: 500;
  color: var(--white-color);
  font-size: 14px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    padding: 15px 18px;
  }
  .lightTheme.wrapper {
    border: 1px solid var(--color-border-dark);
  }
  .currencyWrapper {
    width: 100%;
    grid-column: 1/-1;

    padding: 10px 0;
    cursor: pointer;
    padding-bottom: 20px;
  }
  .itemsContainer {
    display: none;
    border-top: 1px solid var(--color-border-dark);
  }
  .showAll {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px 20px;
    padding-top: 24px;
  }
  .item {
    border: none;
    padding: 0;
  }
  .arrow {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .showAll {
    grid-template-columns: 1fr 1fr;
  }
  .currencyName {
    font-size: 20px;
  }
  .logo {
    max-width: 24px;
  }
}
