.wrapper {
  border: 1px solid var(--color-bg-dark-2);
  padding: 24px;
  background: var(--color-bg-dark-3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  width: 100%;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}

@media only screen and (max-width: 640px) {
  .wrapper {
    padding: 15px;
  }
}
