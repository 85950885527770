.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 24px;
  width: 100%;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}
@media only screen and (max-width: 1199px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
