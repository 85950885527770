.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.heading {
  color: var(--white-color);
  font-weight: 600;
}
