.button {
  background: var(--color-bg-dark-2);
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  border: 6px;
  cursor: pointer;
}
.mlAuto {
  margin-left: auto;
}
@media only screen and (max-width: 350px) {
  .button {
    padding: 10px 8px;
  }
}
