.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 60px 0;
}

.heading {
  color: var(--white-color);

  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}
.highlight {
  color: var(--color-yellow-text);
}
.text {
  text-align: center;
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  padding: 5px;
  border-radius: 8px;
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 40px 0;
  }
  .heading {
    font-size: 32px;
  }
}
@media only screen and (max-width: 400px) {
  .heading {
    font-size: 30px;
  }
}
