.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.item {
  font-size: 14px;
  text-align: left;
  padding: 14px 24px;
  white-space: nowrap;
}
.firstItem {
  color: var(--white-color);
}
.item {
  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
  border-bottom: 1px solid var(--color-bg-dark-2);
}

.amount {
  text-align: right;
}
.pagination {
  padding: 0 20px;
  margin-top: auto;
  padding-bottom: 20px;
}

@media only screen and (max-width: 520px) {
  .item {
    font-size: 12px;
  }
}
