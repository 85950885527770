.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.dropdownMain {
  width: auto;

  height: 0;

  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}
.selectedValue {
  text-transform: uppercase;
}
.icon {
  cursor: pointer;
}

@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    left: 50%;
    transform: translateX(-50%);
    /* bottom: calc(100% + 8px); */
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}

.listItem {
  height: 40px;
  padding: 8px 35px;
  cursor: pointer;
  transition: 0.4s ease;
}

.listItem.active,
.listItem:hover {
  background-color: var(--color-primary);
  color: var(--white-color);
}
.lightTheme .listItem.active,
.lightTheme .listItem:hover {
  color: var(--white);
}
