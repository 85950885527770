.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  width: 90%;
  max-width: 480px;
}

.balance {
  display: flex;
  align-items: left;
  width: 100%;

  font-size: 16px;
  font-weight: 500;
}

.headingContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.text {
  text-align: center;
}

.inputContainer {
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  font-size: 16px;
  font-weight: 500;

  width: 100%;
  border-radius: 8px;

  border: none;
  outline: none;
  border: 1px solid var(--color-border-light);
  transition: 0.4s ease;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 12px 20px;
  position: relative;
}

.label {
  padding: 8px 0;
  padding-right: 15px;
  border-right: 2px solid var(--color-border-dark);
  color: var(--white-color);
  min-width: 89px;
}

.input {
  width: 100%;
}

.input input {
  font-size: 24px;
  font-weight: 500;
  padding: 0 15px;

  width: 100%;
  background: transparent;
  border: none;
  height: auto;
}
.lightTheme .input input {
  box-shadow: none;
}

.input input:focus {
  border: none;
}

.dropdownContainer {
  padding-left: 15px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
