/* table css */
.tableContainer {
  overflow-x: auto;
  width: 100%;
  border-collapse: separate;
  /* background: var(--color-bg-dark-3); */
  border-radius: 20px;
}

.table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
  width: 100%;
  width: 100%;
}
.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.heading {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
}
.heading {
  color: var(--color-grey-text2);

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 13px 24px;
  position: relative;
  border-bottom: 1px solid var(--color-border-dark);
  background: var(--color-bg-dark-3);

  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.tableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
}
.lastHeading {
  text-align: right;
  justify-content: flex-end;
}
