.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;

  width: 100%;
}
.header h6 {
  color: var(--white-color);
}
.viewMore {
  font-size: 14px;
  color: var(--color-grey-text2);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.1px;
  padding: 10px 14px;

  border-radius: 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
}
.right {
  fill: var(--color-grey-text2);
  font-size: 20px;
}
.announcementWrapper {
  padding: 0 10px;

  width: 100%;
}
.announcement {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
  border: 1px solid var(--color-border-dark);
  border-radius: 10px;
}
.info,
.time {
  overflow: hidden;
  color: var(--color-grey-text);
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.time {
  opacity: 0.8;
}
@media only screen and (max-width: 991px) {
  .announcement {
    padding: 10px;
  }
}
@media only screen and (max-width: 40px) {
  .announcementWrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .announcementWrapper {
    padding: 0;
  }
}
