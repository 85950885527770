.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.tabContainer {
  gap: 5px;
}
.styledDropdown {
  border: 1px solid var(--white-color);
  padding: 12px 15px;
  width: 160px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: var(--color-bg-dark-3);
  border-radius: 6px;
}

.dropdownMain {
  width: auto;
  height: 0;
  overflow: hidden;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease-in-out;
}
.styledDropdown .dropdownMain {
  min-width: 100%;
}
.placeholder {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-grey-text2);
  border-bottom: none;
}
.tab {
  color: var(--color-grey-text);
}
.placeholderType {
  right: auto;
  left: 0;
}
.left {
  left: calc(-100% - 25px);
}
.selectedValue {
  text-transform: uppercase;
  width: 100%;
}
.tabContainer .selectedValue {
  text-transform: lowercase;
}

.styledDropdown .placeholder {
  width: 100%;
  color: var(--color-grey-text);
}

.icon {
  cursor: pointer;
  color: inherit;
  margin-left: auto;
}

@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    left: 50%;
    transform: translateX(-50%);
    /* bottom: calc(100% + 8px); */
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}

.listItem {
  height: 40px;
  padding: 8px 35px;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.4s ease;
  white-space: nowrap;
}

.listItem.active,
.listItem:hover {
  background-color: var(--color-primary);
  color: var(--white-color);
}
.listItem:hover {
  background-color: var(--color-bg-dark-3);
  color: var(--white-color);
}
.lightTheme .listItem.active {
  color: var(--white);
}
.lightTheme .listItem:hover {
  background-color: var(--color-primary);
  color: var(--white);
}
@media only screen and (max-width: 520px) {
  .selectedValue {
    font-size: 14px;
  }
}
