.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.heading {
  color: var(--white-color);

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.wrapper {
  background: var(--color-bg-dark-3);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
  border-radius: 6px;
}
.lightTheme .wrapper {
  border: 1px solid var(--color-border-dark);
  border-radius: 6px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 6px;
}
.lightTheme .tabs {
  background: var(--color-bg-dark-4);
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
.lightTheme .activeTab,
.lightTheme .activeTab span {
  color: var(--white-color);
}
.dot {
  display: none;
}
.dekstopView {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .header {
    padding: 10px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .mainWrapper {
    margin-top: -18px;
    margin-bottom: 105px;
  }
}
@media only screen and (max-width: 520px) {
  .mainWrapper {
    gap: 20px;
  }

  .heading {
    font-size: 24px;
  }
  .tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0;
  }
  .tab {
    font-size: 13px;
    padding: 8px 10px;
  }

  .tab span {
    display: none;
  }
  .tab .dot {
    display: inline;
  }
}

@media only screen and (max-width: 380px) {
  .heading {
    font-size: 22px;
  }

  .tab {
    font-size: 11px;
  }
}
/* mobile version css */
.mobileView {
  width: 100%;
  display: none;
}
@media only screen and (max-width: 1280px) {
  .dekstopView {
    display: none;
  }
  .mobileView {
    display: flex;
  }
}
