.dropdown {
  position: relative;
}

.dropdownMain {
  min-width: 290px;
  width: auto;
  height: 0;
  overflow: hidden;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}

@media screen and (max-width: 767px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    left: 100%;
    transform: translateX(-30%);
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}
.top {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-border-dark);
  gap: 10px;
  padding: 10px 15px;
}
.heading {
  color: var(--white-color);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.556%;
}
.userImg {
  max-width: 48px;
  aspect-ratio: 1/1;
  border-radius: 10px;
}
.emailAnduserId {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}
.email {
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.userId {
  font-size: 12px;
  color: var(--color-grey-text);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: var(--color-bg-dark-3);
  padding: 8px;
  border-radius: 6px;
}
.userId span {
  color: var(--color-grey-text2);
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.button {
  display: flex;
  justify-content: center;

  white-space: nowrap;
}
.buttonContainer .verifiedButton {
  border-radius: 6px;
  background: rgba(23, 222, 138, 0.1);
  gap: 4px;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
  border-top: 1px solid var(--color-border-dark);
  border-bottom: 1px solid var(--color-border-dark);
  height: 352px;
  pointer-events: all;
  overflow-y: auto;
}
.list {
  /* display: flex;
  align-items: center; */

  padding: 8px 15px;
  cursor: pointer;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 15px;
  align-items: center;
}

.list:hover {
  background: var(--color-bg-dark-3);
  transition: 0.3s;
}
.lightTheme .list:hover {
  background: var(--color-bg-dark-4);
}
.iconContainer {
  border-radius: 8px;
  background: rgba(102, 135, 251, 0.1);
  padding: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.iconContainer img {
  max-width: 24px;
}
.labeAndInfo {
  width: 100%;
}
.label {
  color: var(--white-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.info,
.date {
  overflow: hidden;
  color: var(--color-grey-text2);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.333%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}
