.container {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 48px;
}
@media only screen and (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
