.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  max-width: 700px;
}

.heading {
  color: var(--white-color);

  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.text {
  color: var(--color-grey-text2);

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
@media only screen and (max-width: 520px) {
  .heading {
    font-size: 30px;
  }
  .text {
    font-size: 15px;
  }
}
