.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.stepItem {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 0px;
  margin-bottom: 80px;
  width: 100%;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
}
.title {
  font-weight: 700;
  font-size: calc(1.3rem + 0.6vw);
}

.stepItem .labelContainer {
  display: flex;
  align-items: center;
  gap: 26px;
  width: 50px;
  min-height: 70px;
}

.stepItem .labelContainer .number {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: var(--color-primary);
  position: relative;
  color: var(--white-color);
}
.lightTheme .stepItem .labelContainer .number {
  color: var(--white);
}
.stepItem .labelContainer .completed {
  background-color: var(--color-green);

  color: var(--white-color);
}
.lightTheme .stepItem .labelContainer .completed {
  color: var(--white);
}

.stepItem .labelContainer .completed::before {
  content: "";
  height: 120px;
  width: 3px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  position: absolute;
  background-color: var(--color-green);
  transition: height 0.3s ease;
}
.stepItem .labelContainer .completed .checkMark {
  font-size: 16px;
  color: var(--white-color);
}
.lightTheme .stepItem .labelContainer .completed .checkMark {
  color: var(--white);
}
/* .stepItem .labelContainer .number .dottedLine {
  position: absolute;
  width: 2px;
  height: 300px;
  border-right: 2px dashed var(--color-grey-text);
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
} */

.stepItem .inputsContainer {
  max-width: 400px;
  width: 100%;
}

.stepItem .addressContainer {
  background-color: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.stepItem .addressContainer .addressLabel {
  color: var(--white-color);
  font-size: 16px;
}

.stepItem .addressContainer .addressWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.stepItem .addressContainer .addressCopyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.stepItem.active .number {
  background-color: var(--color-primary);
}

.address {
  word-break: break-word;
  margin-top: 4px;
  display: block;
  font-size: 16px;
  color: var(--color-grey-text);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 140%;
  background: var(--color-bg-dark);
  padding: 12px;
  border-radius: 8px;
}
.spaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}
.bottomBar {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  padding-top: 10px;
  border-top: 1px solid var(--color-border-dark);
}
.text {
  color: var(--white-color);
}
@media only screen and (max-width: 1024px) {
  .stepItem {
  }
}
@media screen and (max-width: 767px) {
  .stepItem {
    gap: 10px;
  }

  .stepItem .labelContainer {
    min-height: 60px;
  }
}
@media screen and (max-width: 575px) {
  .stepItem {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .stepItem .inputsContainer {
    width: calc(100% - 40px);
    margin-left: 40px;
  }

  .stepItem .labelContainer {
    width: 100%;
  }
  .stepItem .labelContainer .completed::before {
    height: 150px;
  }
}
@media only screen and (max-width: 520px) {
  .stepItem .addressContainer .addressWrapper {
    flex-direction: column;
  }
  .details .text {
    font-size: 12px;
  }
  .stepItem .labelContainer {
    gap: 10px;
  }

  .stepItem .inputsContainer {
    width: calc(100% - 30px);
    margin-left: 30px;
  }
  .stepItem .addressContainer {
    padding: 15px;
  }
}
@media only screen and (max-width: 380px) {
  .stepItem .addressContainer {
    padding: 10px;
  }
  .stepItem .addressWrapper {
    width: 100%;
    margin-left: 0px;
  }
}
