.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.top {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 15px;
  width: 100%;
}
.topContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.heading {
  color: var(--white-color);

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.listContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
  border-top: 1px solid var(--color-border-dark);
  border-bottom: 1px solid var(--color-border-dark);
  width: 100%;
}
.list {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.info {
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.icon {
  max-width: 24px;
}
.image {
  max-width: 100px;
  width: 100%;
  display: block;
}
@media only screen and (max-width: 640px) {
  .left {
    gap: 12px;
  }
  .listContainer {
    padding: 16px 0;
  }
  .heading {
    font-size: 18px;
  }
  .list,
  .info {
    font-size: 14px;
  }

  .topContent .button {
    font-size: 16px;
    height: auto;
    padding: 10px 10px;
  }
  .image {
    max-width: 80px;
  }
}
