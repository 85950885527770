.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.item {
  text-align: left;
  padding: 12px 18px;
  white-space: nowrap;
  font-size: 13px;
}

.item {
  color: var(--color-grey-text);

  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
}
.copyContainer {
  display: flex;
  align-items: center;
  gap: 7px;
}
.copyContainer .destination,
.copyContainer .trxId {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.copyContainer .copyIcon {
  font-size: 14px;
  opacity: 0.7;
  cursor: pointer;
  margin-top: 4px;
  max-width: 18px;
  width: 100%;
}
.lightTheme .copyIcon {
  opacity: 1;
}
.copyContainer .copyIcon:hover {
  opacity: 1;
}
.pair {
  color: var(--color-primary);
}
.buy {
  color: var(--color-green);
}
.sell {
  color: var(--color-red);
}
.status {
  text-align: right;
}
.completed {
  color: var(--color-green);
}
.expired {
  color: var(--color-yellow-text);
}
.canceled {
  color: var(--color-red);
}

.item span {
  font-size: 12px;
  opacity: 0.8;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.deleteIcon {
  cursor: pointer;
  color: var(--whtie-color);
  font-size: 20px;
}
.buttonContainer:hover .deleteIcon {
  color: var(--color-red);
}
.pagination {
  padding: 0 20px;
  margin-top: auto;
  padding-bottom: 20px;
}

@media only screen and (max-width: 520px) {
  .item {
    font-size: 12px;
  }
}
