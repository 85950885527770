.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 30px;
}
.tab {
  color: var(--color-grey-text2);
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
}
.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
}
.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
  max-width: 270px;
  width: 100%;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  background: var(--color-bg-dark-3);
}
.searchContainer input:focus {
  border-color: var(--color-primary);
}
.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}
.filteringContainer {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
}
.filterTab,
.more {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  background: var(--color-bg-dark-3);
  padding: 8px 10px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.filterTab:hover {
  transform: translateY(-3px);
}
.activeFilterTab {
  background: var(--color-primary);
}
.activeFilterTab p {
  color: #fff;
}

/* table css */
.marketTable {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark-3);
  border-radius: 20px;
  padding-top: 15px;
  padding-bottom: 30px;
}
.lightTheme .marketTable {
  border: 1px solid var(--color-border-dark);
  border-radius: 6px;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 100%;
  border-collapse: collapse;
  width: 100%;
}
.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.heading {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
}
.heading {
  color: var(--color-grey-text2);

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 13px 24px;
  position: relative;
  border-bottom: 1px solid var(--color-border-dark);
  background: var(--color-bg-dark-3);

  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.tableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
}
.lastHeading {
  text-align: right;
  justify-content: flex-end;
}
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
