.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.boxWithButton {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
}

.box,
.labelAndValue {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.boxWithButton,
.box {
  border-radius: 12px;
  border: 1px solid var(--color-border-dark);
  background: var(--color-bg-dark-2);
  padding: 12px;
}
.label {
  color: var(--color-grey-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.value {
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
@media only screen and (max-width: 1199px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .wrapper {
    padding: 0 15px;
  }
  .lightTheme {
    padding: 0;
  }
}
@media only screen and (max-width: 640px) {
  .container {
    grid-template-columns: 1fr;
  }
  .boxWithButton {
    flex-direction: column;
    align-items: flex-start;
  }
  .boxWithButton .button {
    margin-left: auto;

    height: auto;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 16px;
  }
}
