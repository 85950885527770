.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  padding: 15px 0;
  background: var(--color-bg-dark-3);

  width: 100%;
  position: relative;
  padding: 15px 0;
  height: 100%;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
  border-radius: 6px;
}
.tabs {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 6px;
}
.lightTheme .tabs {
  background: var(--color-bg-dark-4);
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
