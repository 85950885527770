.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 18px;
  width: 100%;
}

.left {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
}
.valueContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.label {
  font-size: 16px;
  color: var(--color-grey-text);
}
.value {
  font-size: 15px;
  font-weight: 500;
  color: var(--white-color);
  display: flex;
  gap: 5px;
}
.currency {
  font-size: 15px;
  text-transform: uppercase;
  color: var(--color-grey-text);
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 18px;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .wrapper {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
}
