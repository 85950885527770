.wrapper {
  max-width: 320px;

  width: 100%;
  background-color: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
}
.lightTheme .heading {
  border: none;
}
.faqs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faqContainer {
  border-radius: 12px;
  border-left: 4px solid transparent;
  background: var(--color-bg-dark-3);

  box-shadow: 0px 1px 2px 0px rgba(28, 28, 39, 0.05);
}
.lightTheme .faqContainer {
  box-shadow: none;
}
/* .activeFaqContainer {
  border-left: 4px solid var(--color-primary);
} */
.questionContainer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
}
.activeFaqContainer .questionContainer {
  border-bottom: 1px solid var(--color-bg-dark-2);
  padding-bottom: 10px;
}
.question {
  color: var(--color-grey-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.activeFaqContainer .question {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 600;
}
.icon {
  font-size: 16px;
  min-width: 18px;
  margin-left: auto;
  cursor: pointer;
  margin-top: 2px;
}
.answer {
  color: var(--color-grey-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  padding-bottom: 10px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    max-width: 100%;
  }
}
