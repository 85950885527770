.sidebar {
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.heading {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-border-dark-2);
}
.tabs {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.tab {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text {
  font-size: 16px;
  color: var(--color-grey-text2);
  font-weight: 500;
}

.active .text {
  color: var(--color-primary);
}
.complete .text {
  color: var(--color-green);
}
.checkmark {
  display: block;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .sidebar {
    max-width: 100%;
    width: 100%;
    gap: 12px;
  }
  .heading {
    padding-bottom: 12px;
  }
}
