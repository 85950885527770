.text {
  margin: 40px 0;
}

.btn {
  max-width: 144px;
  margin-left: auto;
  margin-right: auto;
}

.passwordChanged img.changed {
  width: 100%;
  max-width: 96px;
  display: block;
  margin: 0 auto;
}
