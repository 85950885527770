.footer {
  padding-top: 48px;
  background-color: var(--color-bg-dark-3);
}

.footer .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
}

@media screen and (max-width: 575px) {
  .footer .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.footer .header .dropdown {
  box-shadow: 0px 2px 2px 0px var(--shadowColor),
    0px 1px 1px 0px var(--shadowColor);
  background-color: var(--color-bg-dark-2);
  padding: 8px 16px;
  border-radius: 6px;
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.footer .linksRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 50px;
  padding: 64px 0;
  border-top: 1px solid var(--color-border-dark);
  border-bottom: 1px solid var(--color-border-dark);
}

@media screen and (max-width: 1199px) {
  .footer .linksRow {
    gap: 20px;
  }
}

@media screen and (max-width: 991px) {
  .footer .linksRow {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

@media screen and (max-width: 575px) {
  .footer .linksRow {
    padding: 30px 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 450px) {
  .footer .linksRow {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
}

.footer .linkCol h5 {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: var(--white-color);
}

.footer .linkCol .links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer .linkCol .links a {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.4s ease;
}

.footer .linkCol .links a:hover {
  color: var(--white-color);
}

.footer .linkCol .links a.seeMore {
  color: var(--color-primary);
}

.footer .footNotes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}

@media screen and (max-width: 767px) {
  .footer .footNotes {
    flex-direction: column;
    gap: 20px;
  }
}

.footer .footNotes .copyRight {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-grey-text2);
}

.footer .footNotes .social {
  display: flex;
  gap: 8px;
  align-items: center;
}

.footer .footNotes .social .box {
  box-shadow: 0px 2px 2px 0px var(--shadowColor),
    0px 1px 1px 0px var(--shadowColor);
  /* box-shadow: 0px 2px 2px 0px #0000000f, 0px 1px 1px 0px #00000008; */
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 6px;
  gap: 10px;
  background-color: var(--color-bg-dark-2);
}

@media screen and (max-width: 575px) {
  .footer .footNotes .social .box {
    width: 30px;
    height: 30px;
  }

  .footer .footNotes .social .box img {
    width: 14px;
  }
}

@media screen and (max-width: 450px) {
  .footer .footNotes .social .box {
    width: 30px;
    height: 30px;
  }
  .footer .linksRow {
    gap: 50px;
  }
}
