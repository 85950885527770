.dropdown {
  position: relative;
}

.dropdownMain {
  min-width: 120px;
  height: 0;
  overflow: hidden;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}
.lightTheme .dropdownMain {
  border: 0;
}
@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    left: 50%;
    transform: translateX(-50%);
    /* bottom: calc(100% + 8px); */
    width: auto;
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

/* .searchContainer {
  position: relative;
  padding: 16px 16px 8px;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
}

.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
} */

.selectedValue {
  display: flex;
  align-items: center;
  gap: 18px;

  cursor: pointer;
}
.list {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 6px;
  padding: 10px 0;
}

.nameAndlogo {
  display: flex;
  align-items: center;
  gap: 8px;
}
.logo {
  max-width: 24px;
  border-radius: 100%;
}

.name {
  color: var(--color-grey-text);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.arrow {
  margin-left: auto;
  cursor: pointer;
  color: var(--color-grey-text);
}
.listItem {
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.4s ease;
  width: 100%;

  display: flex;
  align-items: center;
  gap: 8px;
}

.listItem.active,
.listItem:hover {
  background-color: var(--color-primary);
  color: #ffffff;
}
.lightTheme .listItem.active .name,
.lightTheme .listItem:hover .name {
  color: var(--white);
}

@media only screen and (max-width: 520px) {
  .name {
    font-size: 14px;
  }
}
