.dropdown {
  position: relative;
}

.dropdown .dropdownMain {
  min-width: 280px;
  height: 0;
  overflow: hidden;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}

@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    /* left: 50%;
    transform: translateX(calc(-100% - 15px)); */
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}
.top {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.userImg {
  max-width: 48px;
  aspect-ratio: 1/1;
  border-radius: 10px;
}
.emailAnduserId {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}
.dropdown .email {
  color: var(--white-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.dropdown .userId {
  font-size: 12px;
  color: var(--color-grey-text);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  background: var(--color-bg-dark-3);
  padding: 8px;
  border-radius: 6px;
  box-shadow: var(--box-shadow-main);
}
.userId span {
  color: var(--color-grey-text2);
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.button {
  display: flex;
  justify-content: center;

  white-space: nowrap;
}
.buttonContainer .verifiedButton {
  border-radius: 6px;
  background: rgba(23, 222, 138, 0.1);
  gap: 4px;
}
.dropdown .listContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0;
  border-top: 1px solid var(--color-border-dark);
  border-bottom: 1px solid var(--color-border-dark);
}
.dropdown .list {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 15px;
  cursor: pointer;
}
.dropdown .list:hover {
  background: var(--color-bg-dark-3);
  transition: 0.3s;
  box-shadow: var(--box-shadow-main);
}

.list img {
  max-width: 24px;
  opacity: 0.5;
}
.list .icon {
  font-size: 24px;
  color: var(--color-grey-text);
}
.list:hover .icon {
  color: var(--white-color);
}
.list .label {
  color: var(--color-grey-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.list:hover .label {
  color: var(--white-color);
}
.logout {
  justify-content: flex-start;
  padding: 20px 15px;
}
.logout:hover {
  background: transparent;

  transform: none;
}
