.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topics {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.heading {
  background: var(--color-primary);
  color: var(--white);
  padding: 10px 20px;
  border-radius: 8px;
}
.topic {
  color: var(--color-grey-text2);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.556%;
  padding: 5px 20px;
  cursor: pointer;
}
.topic:hover {
  color: var(--color-primary);
}

.activeTopic {
  color: var(--color-primary);
}
.arrow {
  display: none;
}
@media only screen and (max-width: 1199px) {
  .header {
    cursor: pointer;
    border-bottom: 1px solid var(--color-border-dark);
    padding-bottom: 25px;
  }
  .arrow {
    display: block;
    color: var(--color-grey-text);
    font-size: 20px;
    cursor: pointer;
  }
  .topics {
    display: none;
  }
  .showAllTopic {
    display: block;
  }
  .topic {
    font-size: 16px;
  }
}
@media only screen and (max-width: 520px) {
  .arrow {
    font-size: 18px;
  }
}
