.downloadModal {
  max-width: 460px;
  width: 95%;
}

.downloadModal h3 {
  padding-top: 25px;
  margin-bottom: 12px;
  color: var(--white-color);
  font-size: 24px;
  font-weight: 500;
}

.downloadModal p {
  font-size: 16px;
}

.downloadModal h3,
.downloadModal p {
  text-align: center;
}
.downloadModal h5 {
  font-size: 10px;
}
.downloadModal h6 {
  color: var(--white-color);
  text-align: left;
  font-size: 16px;
}
.downloadModal img {
  max-width: 180px;
  width: 100%;
  display: block;
  margin: auto;
}
.container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  gap: 15px;
}
.box {
  padding: 5px 0;
}
.or {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
}

.or::after {
  content: "";
  position: absolute;
  left: 50%;
  translate: transformX(-50%);
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  background-color: var(--color-border-dark);
}

.orInner {
  padding: 10px 4px;
  width: max-content;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-grey-text-light);
  background-color: var(--color-bg-dark-2);

  opacity: 1;
  position: relative;
  z-index: 1;
  margin: auto 0;
}
.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.downloadModal .logo {
  max-width: 24px;
  margin: 0;
}
.downloadModal .button {
  display: flex;
  padding: 5px 18px;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  margin: 0 auto;
}
@media only screen and (max-width: 520px) {
  .container {
    grid-template-columns: 1fr;
  }
  .or::after {
    left: 0;
    right: 0;
    top: 50%;
    translate: transformY(-50%);

    width: 100%;
    height: 2px;
  }
}
