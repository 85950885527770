@media screen and (max-width: 575px) {
  .tableContainer {
    overflow: auto;
    width: calc(100vw - 40px);
  }
  .tableContainer::-webkit-scrollbar {
    background-color: var(--base-200);
    height: 5px;
  }

  .tableContainer::-webkit-scrollbar-thumb {
    background-color: var(--base-300);
    height: 5px;
  }
}

.table {
  min-width: 460px;
  width: 100%;
}

.table thead th {
  border-bottom: 1px solid var(--color-border-dark);
  font-size: 12px;
  padding: 8px 0;
  color: var(--base-100);
  text-align: left;
  font-weight: 400;
}

.table thead th:last-child,
.table tbody td:last-child {
  text-align: right;
}

.table tbody tr td {
  border-bottom: 1px solid var(--color-border-dark);
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}

.table tbody tr td h6 {
  font-size: 14px;
  color: var(--white-color);
}

.table tbody tr td .status.Completed {
  color: var(--color-green);
}

.table tbody tr td .status.Failed {
  color: var(--color-red);
}

.log .btn {
  height: 40px;
  font-size: 16px;
  padding: 8px 18px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}
