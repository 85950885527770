.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0px;

  background: var(--color-bg-dark-3);
}

.lightTheme {
  border: 1px solid var(--color-border-dark);
  border-radius: 6px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0px;
  padding: 0 15px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
  max-width: 270px;
  width: 100%;
  margin-left: auto;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  background: var(--color-bg-dark-3);
}
.searchContainer input:focus {
  border-color: var(--color-primary);
}
.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}

.tabs {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 8px;
}
.lightTheme .tabs {
  background: var(--color-bg-dark-4);
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
@media only screen and (max-width: 520px) {
  .header {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .searchContainer {
    width: 100%;
    margin: 0 auto;
  }
  .tabs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .tab {
    text-align: center;
  }
  .tab:last-child {
    grid-column: 1/-1;
  }
}
