.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  width: 90%;
  max-width: 480px;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: right;
  /* gap: 10px;
  width: 100%;
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color:  var(--white-color);
  */
}

.headingContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.text {
  text-align: left;
  width: 100%;
}

.inputContainer {
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  font-size: 16px;
  font-weight: 500;

  width: 100%;
  border-radius: 8px;

  border: none;
  outline: none;
  border: 1px solid var(--color-border-light);
  transition: 0.4s ease;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 12px 20px;
  position: relative;
}
.lightTheme .inputContainer {
  border: 1px solid var(--color-border-dark);
}
.label {
  padding: 8px 0;
  padding-right: 15px;
  border-right: 3px solid var(--color-border-dark);
  color: var(--white-color);
  min-width: 89px;
}

.input {
  width: 100%;
}

.input input {
  font-size: 24px;
  font-weight: 500;
  padding: 0 15px;

  width: 100%;
  background: transparent;
  border: none;
  height: auto;
}
.lightTheme .input input {
  box-shadow: none;
}
.input input:focus {
  border: none;
}

.arrowContainer {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-bg-dark);
  border: 4px solid var(--color-bg-dark-2);
  position: absolute;
  left: 80%;
  top: 0;
  transform: translate(-50%, -80%);
}
.lightTheme .arrowContainer {
  border: 1px solid var(--color-border-dark);
}
.toArrow {
  font-size: 18px;
}

.currencyAmount,
.fromTo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.dropdownContainer {
  padding-left: 15px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
