.cards {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 56px 0;
}

.cards .card {
  cursor: pointer;
  border-radius: 12px;
  padding: 16px;
  background-color: var(--color-bg-dark-3);
  border: 1px solid var(--color-border-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.cards .card .left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.cards .card .left h5 {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.cards .card .left p {
  color: var(--color-grey-text-light);
  font-size: 400;
  font-size: 14px;
}
