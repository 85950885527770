.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 60px 0;
  border-top: 1px solid var(--color-border-dark);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 588px;
  margin: 0 auto;
}

.heading {
  color: var(--white-color);

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}
.highlight {
  color: var(--color-yellow-text);
}
.text {
  text-align: center;
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
.card {
  display: flex;
  flex-direction: column;
  gap: 28px;
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark);
  padding: 32px;
  border-radius: 20px;
}
.lightTheme .card {
  border: 1px solid var(--color-border-dark);
}
.imgAndId {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
}
.img {
  max-width: 96px;
  width: 100%;
}
.id {
  color: var(--color-bg-dark);
  text-align: right;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 111.111%;
}
.titleAndInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.title {
  color: var(--white-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.info {
  color: var(--color-grey-text2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
@media only screen and (max-width: 1199px) {
  .container {
    gap: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 40px 0;
  }
  .heading {
    font-size: 32px;
  }
  .text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 640px) {
  .container {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 400px) {
  .heading {
    font-size: 30px;
  }
}
