.myWrapper {
  min-height: auto;
}
.navbar {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-border-dark);
  background: var(--color-bg-dark);
  padding-left: 32px;
  padding-right: 32px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}

.lightTheme {
  border-bottom: 0;
  background-color: var(--color-bg-dark-6);

  p,
  .link {
    color: var(--white);
    /* Replace with your desired color code or color name */
  }
}

.navbar .mainLeft {
  display: flex;
  align-items: center;
}

.avatar {
  cursor: pointer;
}

.loggedUser {
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .navbar {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* .navbar .mainLeft .logo {
  } */
  .avatar {
    max-width: 28px;
  }
}

.navbar .mainLeft .navigation {
  display: flex;
  align-items: center;
  gap: 16px;
  border-left: 1px solid var(--color-border-dark);
  padding-left: 20px;
  margin-left: 20px;
}

.authenticatedRoutes {
  display: flex;
  align-items: center;
  gap: 16px;

  padding-left: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 1199px) {
  .navbar .mainLeft .navigation {
    display: none;
  }
}

.navbar .mainLeft .navigation .link {
  font-size: 14px;
  transition: 0.4s ease;
}

.navbar .mainLeft .navigation .link:hover {
  color: var(--white-color);
}

.navbar .mainRight {
  display: flex;
  align-items: center;
}

.navbar .mainRight .navigation {
  display: none;
}

.navbar .mainRight .buttons {
  display: flex;
  gap: 6px;
  position: relative;

  align-items: center;
}

.navbar .mainRight .buttons.first {
  padding-right: 20px;
  margin-right: 20px;
}

.navbar .mainRight .buttons.first::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 1px;
  background-color: var(--color-bg-dark);
}

.hamburger {
  cursor: pointer;

  font-size: 24px;
}
.lightTheme .hamburger {
  color: var(--white);
}

@media screen and (min-width: 1200px) {
  .hamburger,
  .close {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .mainRightSm {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  .lightTheme .close {
    color: var(--white);
  }

  .mainRightSm {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .mainRightSm .independentBtns {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .darkOverlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
  }

  .darkOverlay.active {
    opacity: 1;
    pointer-events: all;
  }

  .navbar .mainRight {
    position: fixed;
    flex-direction: column;
    height: 100vh;
    right: -320px;
    top: 0;
    width: 320px;
    padding: 40px 24px;
    align-items: flex-start;

    z-index: 5;
    transition: 0.4s ease;
  }
  .lightTheme .mainRight {
    background-color: var(--color-bg-dark-6);
  }

  .navbar .mainRight.active {
    right: 0;
    background-color: var(--color-bg-dark-2);
  }
  .lightTheme .mainRight.active {
    background-color: var(--color-bg-dark-6);
  }
  .navbar .mainRight .navigation {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .authenticatedRoutes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    padding: 0;
    margin: 0;
  }

  .first .authenticatedRoutes .link {
    font-size: 14px;
    transition: 0.4s ease;
  }

  .navbar .mainRight .buttons.first {
    display: flex;

    gap: 15px;
    margin: 24px 0;
  }

  .navbar .mainRight .buttons.first > * {
    justify-content: center;
  }

  .navbar .mainRight .buttons.first::after {
    display: none;
  }

  .navbar .mainRight .buttons.second {
    display: none;
  }
}
