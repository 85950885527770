.wrapper {
  width: 100%;
}

.row {
  transition: 0.3s;
  border-radius: 10px;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr) 80px 35px;

  gap: 0;
  padding: 0 5px;
}
/* .row:hover {
  background: var(--color-bg-dark-2);
} */
.row:last-child {
  border: none;
}

.heading,
.dropdownPlaceholder {
  text-align: left;

  white-space: nowrap;
  color: var(--color-grey-text2);

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 13px 5px;
  position: relative;
  border-bottom: 1px solid var(--color-border-dark);
}
.dropdownActive {
  color: var(--white-color);
}
.tableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
  opacity: 0.5;
}
.lightTheme .tableHeading {
  opacity: 1;
}
.lastHeading {
  text-align: right;
  justify-content: flex-end;
}
.dropdownPlaceholder {
  border: none;
  padding: 0;
}
.cancelAll {
  color: var(--color-red);
  cursor: pointer;
  opacity: 1;
  text-align: right;
}
.br {
  display: none;
}
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 360px) {
  .br {
    display: inline;
  }
  .row {
    grid-template-columns: repeat(2, 1fr) 50px 24px;
  }
}
