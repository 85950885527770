.wrapper {
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 12px;
  padding: 0 15px;
  padding-bottom: 0;
  width: 100%;
}
.title {
  color: var(--white-color);

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.layOutWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.layOutIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-bg-dark-3);
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  width: 28px;
  height: 28px;
  cursor: pointer;
  padding: 8px;
  opacity: 0.5;
}
.lightTheme .layOutIconContainer {
}
.acitveLayOut {
  opacity: 1;
}
.layOutIcon {
  width: 24px;
  cursor: pointer;
}
.filteringWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
.dropdownWrapper {
  background: var(--color-bg-dark-2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 6px;
  border-radius: 6px;
}
.optionContainer {
  width: 28px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-bg-dark-2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 6px;
}
.option {
  max-width: 18px;
  display: block;
  margin: 0 auto;
}
.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.listHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--color-border-dark);
}
.container {
  height: calc(100% - 100px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-content: space-between;

  gap: 20px;
}
.container2 {
  grid-template-rows: 1fr auto;
}
.container3 {
  grid-template-rows: auto 1fr;
}
.listContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: flex-start;
  gap: 8px 0px;
  overflow-y: hidden;
  max-height: 401px;
  z-index: 1;
}
.askContainer {
  align-content: flex-end;
}
.fullHeight {
  max-height: 819px;
  align-content: flex-start;
}
.listContainer:hover {
  overflow-y: auto;
}
.heading {
  color: var(--color-grey-text2);
  opacity: 0.5;
  text-align: right;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px 15px;
}
.lightTheme .heading {
  color: var(--color-grey-text-2);
  opacity: 1;
}
.price {
  color: var(--color-red);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 181.818%;
}

.amount,
.price,
.total {
  padding: 0 15px;
}

.amount,
.total {
  color: var(--color-grey-text);
  text-align: right;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 181.818% */
}
.total {
  position: relative;
}
.total::after {
  content: " ";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: var(--width);
  right: 0;
  background: rgb(250, 75, 85, 0.1);
  z-index: -1;

  /* --color-green: #11d080; */
}
.bids .total::after {
  background: rgb(17, 208, 128, 0.1);
}

.amount {
  text-align: left;
}

.positivePrice {
  color: var(--color-green);
}
.negativePrice {
  color: var(--color-red);
}
.spaceBetween {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 4px 15px;
  width: 100%;
  margin-top: auto;
}
.spaceBetween.marginTop {
  margin-top: 0;
}
.growth {
  color: (--color-green);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
}
.negativeGrowth {
  color: var(--color-red);
}
.positiveGrowth {
  color: (--color-green);
}
.downArrow {
  color: var(--color-red);
  font-size: 16px;
  min-width: 18px;
}
.upArrow {
  color: (--color-green);
  font-size: 16px;
  min-width: 18px;
}
.more {
  color: var(--color-grey-text-light);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-left: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;

  background: linear-gradient(180deg, rgba(21, 30, 44, 0) 0%, #151e2c 100%);
  z-index: 1;
  border-radius: 0 0 24px 24px;
}
.listContainer:hover .overlay {
  display: none;
}

.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
@media only screen and (max-width: 1440px) {
  .container {
    height: calc(100% - 93px);
  }
  .listContainer {
    max-height: 606px;
  }
  .fullHeight {
    max-height: 1232px;
  }
}
@media only screen and (max-width: 1199px) {
  .listContainer {
    max-height: 300px;
  }
}
