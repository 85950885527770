.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.heading {
  color: var(--white-color);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 116.667%;
}
.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
.coinBalance,
.stakingApi {
  padding: 16px;
  border: 1px solid var(--color-bg-dark-2);
  background: var(--color-bg-dark-3);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  width: 100%;
}
.coinBalance {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.lightTheme .coinBalance {
  border: 1px solid var(--color-border-dark);
  border-radius: 6px;
}
.label {
  color: var(--white-color);

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.amountContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.amount {
  color: var(--white-color);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
.usdAmount {
  color: var(--color-grey-text2);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.stakingApi {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.addressWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.addressContainer {
  background: var(--color-bg-dark);
  padding: 10px 8px;
  border-radius: 6px;
  width: 100%;
}
.address {
  overflow: hidden;
  color: var(--color-grey-text);
  white-space: nowrap;
  max-width: 180px;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.copyContainer {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: var(--color-border-dark);
  border-radius: 6px;
  width: 40px;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.copyContainer:hover {
  transform: translateY(-3px);
}
.copyIcon {
  max-width: 18px;
}
.earningFrom {
  color: var(--color-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
@media only screen and (max-width: 1199px) {
  .container {
    grid-template-columns: 1fr;
  }
  .address {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .heading {
    font-size: 32px;
  }
  .label {
    font-size: 18px;
  }
  .amount {
    font-size: 28px;
  }

  .address {
    max-width: 200px;
  }
}
@media only screen and (max-width: 640px) {
  .heading {
    font-size: 30px;
  }
  .amount {
    font-size: 24px;
  }
}
@media only screen and (max-width: 520px) {
  .coinBalance,
  .stakingApi {
    padding: 12px;
  }
}
@media only screen and (max-width: 450px) {
  .heading {
    font-size: 28px;
  }
  .amount {
    font-size: 20px;
  }
  .usdAmount {
    font-size: 12px;
  }
  .label {
    font-size: 16px;
  }
}
