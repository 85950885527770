.wrapper {
  width: 100%;

  overflow: hidden;
}

.chart {
  height: calc(100vh - 80px);
  max-height: 454px;
  border-radius: 8px;
  padding-top: 4px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}
