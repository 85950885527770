.feedback .text {
  margin: 32px 0;
  text-align: center;
}

.feedback img {
  max-width: 100%;
  display: block;
  margin: auto;
}

.feedback .buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
}
