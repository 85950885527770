.wrapper {
  background: var(--color-bg-dark-3);
  padding: 18px 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
}

.lightTheme {
  border: 1px solid var(--color-border-dark);
}
.comissionRate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 12px;
  background: var(--color-primary-light);
  padding: 14px 16px;
}
.text {
  color: var(--color-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.rate {
  color: var(--color-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 142.857%;
  margin-left: auto;
}
.box {
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  border-radius: 12px;
  border: none;
  outline: none;
  border: 1px solid var(--color-border-dark);
  transition: 0.4s ease;
  display: grid;
  grid-template-columns: 45px 1fr auto;
  align-items: center;
  gap: 15px;
  padding: 10px 16px;
}
.label,
.value {
  color: var(--white-color);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}
.label {
  padding-right: 15px;
  border-right: 1px solid var(--color-border-dark);
}
.value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}
/* .lightTheme .label,
.lightTheme .value {
  color: var(--color-grey-text);
} */
.copyContainer {
  background: var(--color-primary);
  padding: 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 30px;
  width: 30px;
  height: 30px;
}
.copyIcon {
  color: var(--white);
  font-size: 20px;
}
@media only screen and (max-width: 1199px) {
  .wrapper {
    max-width: 550px;
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 12px;
  }

  .box {
    padding: 10px 12px;
  }
  .copyContainer {
    padding: 7px;
  }
  .copyIcon {
    font-size: 16px;
  }
}
@media only screen and (max-width: 450px) {
  .wrapper {
    padding: 10px;
  }

  .box {
    padding: 10px 12px;
    grid-template-columns: 40px 1fr auto;
  }
  .value,
  .label {
    font-size: 12px;
  }
  .label {
    padding-right: 8px;
  }
}
