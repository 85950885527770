.wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin: 0 auto;
}

.heading {
  color: var(--white-color);

  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}
.highlight {
  color: var(--color-yellow-text);
}
.text {
  text-align: center;
  color: var(--color-grey-text2);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  max-width: 588px;
  margin: 0 auto;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 6px;
  margin: 0 auto;
  margin-top: 15px;
}
.lightTheme .tabs {
  background: var(--color-bg-dark-4);
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
.lightTheme .activeTab,
.lightTheme .activeTab span {
  color: var(--white-color);
}
.container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 40px;
}
@media only screen and (max-width: 1199px) {
  .container {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    gap: 40px;
  }
  .tabs {
    max-width: 500px;
    margin-top: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 40px;
  }
  .text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 450px) {
  .tab {
    font-size: 15px;
    padding: 10px 12px;
  }
}
@media only screen and (max-width: 400px) {
  .heading {
    font-size: 36px;
  }
}
