.inputContainer {
  max-width: 402px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.wFull {
  max-width: 100%;
}
.label {
  font-size: 14px;
}
.lightTheme .label {
  color: var(--white-color);
}
.input {
  background-color: var(--color-bg-dark-3);
  box-shadow: var(--box-shadow-main);
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
  outline: none;
  border: 1px solid var(--color-border-light);
  transition: 0.4s ease;
}

.input.hasError {
  border-color: var(--color-red);
}

.helperError {
  color: var(--color-red);
  font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input:focus {
  border-color: var(--color-primary);
}

.input::placeholder {
  color: var(--color-grey-text-light);
  opacity: 0.5;
}

.inputContainer {
  position: relative;
}

.eye {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
}
