/* table css */

.tableContainer {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;

  border-radius: 20px;

  padding-bottom: 30px;
  width: 100%;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 100%;
  border-collapse: collapse;
  width: 100%;
}
.row {
  transition: 0.3s;
  border-radius: 10px;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.heading,
.dropdownPlaceholder {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
  color: var(--color-grey-text2);

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 13px 18px;
  position: relative;
  border-bottom: 1px solid var(--color-border-dark);
}
.dropdownActive {
  color: var(--white-color);
}
.tableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
  opacity: 0.5;
}
.lightTheme .tableHeading {
  opacity: 1;
}
.lastHeading {
  display: flex;

  justify-content: flex-end;
}
.dropdownPlaceholder {
  border: none;
  padding: 0;
}
.cancelAll {
  color: var(--color-red);
  cursor: pointer;
  opacity: 1;
}
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}
/* Firefox */
.tableContainer {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) rgba(43, 58, 80, 0.65);
  border-radius: 5px;
}

/* Chrome, Edge, and Safari */
.tableContainer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-track {
  background: var(
    --Radial,
    radial-gradient(
      99.99% 99.99% at 50% 0.01%,
      rgba(43, 58, 80, 0.65) 0%,
      rgba(23, 33, 48, 0.8) 100%
    )
  );
  border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
}
