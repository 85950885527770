.wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.lightTheme {
  border-radius: 6px;
  border: 1px solid var(--color-border-dark);
  padding-top: 15px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px 15px;
  padding: 0 15px;
}

.searchContainer {
  position: relative;

  padding-right: 0;
  padding-left: 10px;

  max-width: 270px;
  width: 100%;
  margin-left: auto;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 6px);
  transform: translateY(calc(-50% + 2px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 36px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  background: var(--color-bg-dark-3);
}
.searchContainer input:focus {
  border-color: var(--color-primary);
}
.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}
/* table css */
.tableContainer {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;

  background: var(--color-bg-dark-3);
  border-radius: 20px;

  padding-bottom: 30px;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 100%;
  border-collapse: collapse;
  width: 100%;
}
.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.row:last-child {
  border: none;
}

.heading {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
}
.heading {
  color: var(--color-grey-text2);

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 13px 24px;
  position: relative;
  border-bottom: 1px solid var(--color-border-dark);
  background: var(--color-bg-dark-3);

  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.tableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
  opacity: 0.7;
}
.lastHeading {
  text-align: right;
  justify-content: flex-end;
}
.pagination {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 520px) {
  .searchContainer {
    width: 100%;
    margin-left: auto;
  }
}
