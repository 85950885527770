.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  padding: 15px 0;
  background: var(--color-bg-dark-3);

  width: 100%;
  position: relative;
  padding: 15px 0;
}
.lightTheme {
  border: 1px solid var(--color-border-dark);
}
.tabs {
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
  transition: 0.3s;
  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 8px;
}
.lightTheme .tabs {
  background: var(--color-bg-dark-4);
}
.tab {
  padding: 10px 15px;
  transition: 0.3s;
  border-radius: 6px;
  cursor: pointer;
}

.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  background: var(--color-bg-dark-2);
}
.activeTab {
  color: var(--white-color);
}
.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.listHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px 0px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--color-border-dark);
}
.listContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px 0px;
  overflow-y: hidden;
  max-height: 382px;
}
.listContainer:hover {
  overflow-y: auto;
}
.heading {
  color: var(--color-grey-text2);
  opacity: 0.5;
  text-align: right;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px 15px;
}
.lightTheme .heading {
  color: var(--color-grey-text-2);
  opacity: 1;
}
.price {
  color: var(--color-red);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 181.818%;
}
.amount,
.price,
.total {
  padding: 0 15px;
}

.amount,
.total {
  color: var(--color-grey-text);
  text-align: right;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 181.818% */
}
.amount {
  text-align: left;
}

.positivePrice {
  color: var(--color-green);
}
.negativePrice {
  color: var(--color-red);
}
.spaceBetween {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 8px 15px;
  width: 100%;
}
.growth {
  color: var(--color-green);

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
}
.negativeGrowth {
  color: var(--color-red);
}
.positiveGrowth {
  color: var(--color-green);
}
.downArrow {
  color: var(--color-red);
  font-size: 16px;
  min-width: 18px;
}
.upArrow {
  color: var(--color-green);
  font-size: 16px;
  min-width: 18px;
}
.more {
  color: var(--color-grey-text-light);

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-left: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;

  background: linear-gradient(180deg, rgba(21, 30, 44, 0) 0%, #151e2c 100%);
  z-index: 1;
  border-radius: 0 0 24px 24px;
}
.listContainer:hover .overlay {
  display: none;
}

.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
@media only screen and (max-width: 1440px) {
  .listContainer {
    max-height: 787px;
  }
}
@media only screen and (max-width: 1199px) {
  .listContainer {
    max-height: 300px;
  }
}
@media only screen and (max-width: 520px) {
  .tab {
    font-size: 13px;
    padding: 8px 10px;
    text-align: center;
  }
}
@media only screen and (max-width: 380px) {
  .tab {
    font-size: 11px;
  }
}
