.scanModal h3 {
  margin-bottom: 12px;
  color: var(--white-color);
  font-size: 24px;
  font-weight: 500;
}

.scanModal p {
  font-size: 16px;
}

.scanModal h3,
.scanModal p {
  text-align: center;
}

.scanModal img {
  max-width: 100%;
  display: block;
  margin: auto;
}
