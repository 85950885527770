.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 444px;
  align-items: center;
  gap: 18px;
}
@media only screen and (max-width: 1024px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 991px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
