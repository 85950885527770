.container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
  background: linear-gradient(
    99deg,
    rgba(102, 135, 251, 0.1) 0%,
    rgba(102, 135, 251, 0.05) 100%
  );
  padding: 24px;
  border-radius: 15px;
  background: linear-gradient(
    99deg,
    rgba(102, 135, 251, 0.1) 0%,
    rgba(102, 135, 251, 0.05) 100%
  );
  width: 100%;
}
.heading {
  color: var(--white-color);

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
}
.info {
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.info span {
  color: var(--color-primary);
}
.left {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: var(--color-primary);
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}
.right {
  fill: var(--color-grey-text2);
  font-size: 20px;
}
.image {
  max-width: 100px;
  display: block;
  width: 100%;
}
@media only screen and (max-width: 640px) {
  .container {
    grid-template-columns: 1fr;
    padding: 18px;
    gap: 25px;
    padding-bottom: 24px;
  }
  .left {
    gap: 3px;
  }
  .heading {
    font-size: 18px;
  }
  .info {
    font-size: 14px;
  }
  .image {
    order: -1;
    max-width: 80px;
  }
  .button {
    font-size: 16px;
  }
}
