.scanModal h3 {
  margin-bottom: 12px;
  color: var(--white-color);
  font-size: 24px;
  font-weight: 500;
}

.scanModal p {
  font-size: 16px;
  color: var(--base-100);
}

.scanModal h3,
.scanModal p {
  text-align: center;
}

.scanModal .code {
  margin: 32px 0;
}

.scanModal .code img {
  width: 180px;
  max-width: 100%;
  display: block;
  margin: auto;
}

.scanModal .copy {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  height: 36px;
  border: 1px soild var(--base-300);
  background-color: var(--base-400);
  max-width: 180px;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
}

.scanModal .short {
  font-size: 14px;
}

.scanModal .buttons {
  display: flex;
  margin-top: 32px;
  gap: 8px;
  justify-content: center;
}
