.modalOverlay {
  background: rgba(23, 24, 28, 0.5);
  backdrop-filter: blur(6px);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 6;
  transition: 0.3s ease-in-out;
  pointer-events: none;
  cursor: pointer;
}
.lightTheme {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
  z-index: 6;
  max-width: 95%;

  /* my */
  margin: 0 auto;
  max-height: 680px;
  overflow-y: auto;
  /* max-height: calc(100vh - 80px);
  overflow: auto; */
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
.short {
  max-width: 480px;
}
.xShort {
  max-width: 452px;
}

.modal.styled {
  padding: 20px;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 40px 64px -16px var(--shadowColor);
  border-radius: 20px;
}
@media screen and (max-width: 575px) {
  .modal.styled {
    padding: 20px;
  }
}

.modalOverlay.active {
  opacity: 1;
  pointer-events: all;
}

.modal.active {
  pointer-events: all;
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .modal {
    max-height: 600px;
    overflow-y: auto;
  }
}
@media only screen and (max-width: 520px) {
  .modal.styled {
    padding: 18px 12px;
  }
  .modal {
    max-height: 450px;
    overflow-y: auto;
  }
}
