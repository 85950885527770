.mobileComponent {
}
.buttonContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  top: auto;
  width: 100%;

  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 10px;
  background: var(--color-bg-dark-2);
  box-shadow: 0px -24px 32px -8px rgba(0, 0, 0, 0.48);
  display: none;
  z-index: 8;
}
@media only screen and (max-width: 767px) {
  .buttonContainer {
    display: flex;
  }
}
