.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  white-space: normal;
  position: absolute;
  max-width: 100vw;
}

.dropdownMain {
  min-width: 270px;

  padding: 12px;
  height: 0;

  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}
.selectedValue {
  text-transform: uppercase;
}
.icon {
  cursor: pointer;
}
@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    /* left: 50%;
    transform: translateX(-50%); */
    /* bottom: calc(100% + 8px); */
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}
.container {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.img {
  max-width: 48px;
  width: 100%;
}
.textContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.heading {
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
}

.info {
  font-size: 14px;
  line-height: 130%;
  width: 100%;
  font-weight: 500;
  color: var(--color-grey-text2);
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
}
@media only screen and (max-width: 380px) {
  .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .buttonContainer {
    width: 100%;
    justify-content: center;
  }
  .heading,
  .info {
    text-align: center;
    width: 100%;
  }
}
