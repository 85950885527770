.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  color: var(--white-color);

  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  border-bottom: 1px solid var(--color-border-dark);
  padding-bottom: 18px;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 30px;
}
.tab {
  color: var(--color-grey-text2);
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.3s;
}
.tab:hover {
  transform: translateY(-3px);
}
.activeTab {
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
}
.searchContainer {
  position: relative;
  padding: 16px 16px 8px;
  max-width: 270px;
  width: 100%;
  margin-left: auto;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 12px);
  transform: translateY(calc(-50% + 4px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 42px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  background: var(--color-bg-dark-3);
}
.searchContainer input:focus {
  border-color: var(--color-primary);
}
.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}

@media only screen and (max-width: 640px) {
  .header {
    gap: 10px;
  }
  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 15px;
    max-width: 400px;
    margin: 0 auto;
  }
  .tab {
    text-align: center;
    max-width: 150px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 380px) {
  .heading {
    font-size: 22px;
  }
  .tab {
    font-size: 14px;
  }
}
