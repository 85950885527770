.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.dropdownMain {
  width: calc(100% + 15px);
  height: 0;
  overflow: hidden;
  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}
.selectedValue {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 8px;
}
.icon {
  cursor: pointer;
  margin-left: auto;
}
.listItem {
  display: flex;
  align-items: center;
  width: 100%;
}
.logo {
  max-width: 22px;
  width: 100%;
}

@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    left: 50%;
    transform: translateX(-50%);
    /* bottom: calc(100% + 8px); */
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}

.listItem {
  padding: 12px 15px;
  cursor: pointer;
  transition: 0.4s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.listItem.active,
.listItem:hover {
  background-color: var(--color-primary);
  color: var(--white-color);
}
.lightTheme .listItem.active,
.lightTheme .listItem:hover {
  color: var(--white);
}
