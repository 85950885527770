.wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.switchCheckbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 35px;
  height: 20px;

  border-radius: 15px;
  position: relative;
  transition: background-color 0.2s;
  background: rgba(255, 255, 255, 0.2);
}
.switchLabel.active {
  background: var(--color-primary);
}
.lightTheme .switchLabel {
  background: #000;
}
.lightTheme .switchLabel.active {
  background: var(--color-primary);
}
.switchLabel .switchButton {
  content: "";
  position: absolute;
  top: 4px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.2s;
  background: var(--color-grey-text);
  box-shadow: 0px 0px 3.68182px rgba(38, 44, 71, 0.16);
}
.lightTheme .switchLabel .switchButton {
  background: var(--white);
  box-shadow: 0px 0px 3.68182px rgba(38, 44, 71, 0.16);
}

.switchLabel .switchButton2 {
  content: "";
  position: absolute;
  top: 6px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.2s;
  background: #000;
  box-shadow: 0px 0px 3.68182px rgba(38, 44, 71, 0.16);
}

.switchCheckbox:checked + .switchLabel .switchButton {
  left: calc(100% - 5px);
  transform: translateX(-100%);
  background: var(--white);
}

/* .switchLabel:active .switchButton {
  width: px;
} */
