.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  width: 100%;
  padding: 0 24px;
  padding-top: 24px;
}

.header h6 {
  color: var(--white-color);
}

.viewMore {
  font-size: 14px;
  color: var(--color-grey-text2);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.1px;
  padding: 10px 14px;

  border-radius: 6px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
}

.right {
  fill: var(--color-grey-text2);
  font-size: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  background: var(--color-bg-dark);
  padding: 5px;
  border-radius: 10px;
  margin: 0 px;
}
.lightTheme .tabs {
  background-color: var(--color-bg-dark-4);
}

.tab {
  color: var(--color-grey-text2);
  font-size: 14px;
  font-weight: 400;
  line-height: 114.286%;
  border-radius: 10px;
  background: transparent;
  border: 1px solid transparent;
  outline: none;
  cursor: pointer;
  padding: 11.7px 15px;
  transition: 0.3s;
  border-radius: 8px;
  white-space: nowrap;
}

.tab:hover {
  opacity: 1;

  transform: none;
}

.activeTab {
  color: var(--white-color);
  background: var(--color-bg-dark-2);
}

.marketTable {
  overflow-x: auto;
  width: 100%;
  border-collapse: collapse;
  max-height: 480px;
}
.extraHeight {
  max-height: 580px;
}
.moreExtraHeight {
  max-height: 680px;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  width: 100%;
  border-collapse: collapse;
  width: 100%;
}
.row {
  transition: 0.3s;
}
.row:hover {
  background: var(--color-bg-dark-2);
}
.lightTheme .row:hover {
  background: var(--color-bg-dark-4);
}
.row:last-child {
  border: none;
}

.heading,
.item {
  text-align: left;
  padding: 8px 24px;
  white-space: nowrap;
}

.heading {
  color: var(--color-grey-text2);

  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 8px 24px;
  padding-bottom: 12px;
  position: relative;
  border-bottom: 1px solid var(--color-border-dark);
  background: var(--color-bg-dark-3);

  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.tableHeading {
  display: flex;
  align-items: center;
  gap: 3px;
}

.lastHeading {
  text-align: right;
  justify-content: flex-end;
}

.item {
  color: var(--white-color);

  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
}

.negativeAmount {
  color: var(--color-red);
}

.positiveAmount {
  color: var(--color-green);
}

.logoAndNameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}

.nameContainer p {
  font-weight: 500;
  color: var(--white-color);
  font-size: 14px;
}

.nameContainer span {
  font-size: 12px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pagination {
  padding: 0 20px;
  margin-top: auto;
  padding-bottom: 20px;
}

@media only screen and (max-width: 520px) {
  .tableContainer {
    gap: 18px;
  }
  .header {
    padding: 0 15px;
    padding-top: 0px;
    padding-top: 18px;
  }
  .tab {
    font-size: 13px;
    padding: 11.7px 12px;
  }
  .logoAndNameContainer {
    gap: 8px;
  }

  .logo {
    width: 27px;
    height: 27px;
  }
  .heading {
    font-size: 13px;
    padding: 8px 18px;
  }
  .item {
    font-size: 12px;
    padding: 8px 18px;
  }
}

/* Firefox */
/* .marketTable {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) rgba(43, 58, 80, 0.65);
  border-radius: 5px;
} */

/* Chrome, Edge, and Safari */
/* .marketTable::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 5px;
}

.marketTable::-webkit-scrollbar-track {
  background: var(
    --Radial,
    radial-gradient(
      99.99% 99.99% at 50% 0.01%,
      rgba(43, 58, 80, 0.65) 0%,
      rgba(23, 33, 48, 0.8) 100%
    )
  );
  border-radius: 5px;
}

.marketTable::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 100vh;
  border: none;
  border-radius: 5px;
} */
