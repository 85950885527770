.dontMiss {
  padding: 32px;
  background-color: var(--color-bg-dark-3);
  border-radius: 20px;
  max-width: 402px;
}

.dontMiss h2 {
  margin-bottom: 12px;
}
.lightTheme h2 {
  color: var(--white-color);
}

.dontMiss h2,
.dontMiss p {
  text-align: center;
}

.dontMiss p {
  color: var(--color-grey-text-light);
}

.dontMiss img {
  width: 100%;
  display: block;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}
