.wrapper {
  width: 100%;

  background: var(--color-bg-dark);
  padding: 7px 0px;
  /* display: grid;
  grid-template-columns: auto 1fr auto; */
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  position: relative;
}
.tabs {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.tabs::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

.tabContainer {
  /* position: absolute;
  left: 22px; */
  display: flex;
  gap: 6px;
  align-items: center;
  background: var(--color-bg-dark);
  padding-right: 5px;
}
.text {
  color: var(--color-grey-text2);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.text:hover {
  color: var(--white-color);
}
.text.active {
  color: var(--white-color);
}
/* .highlight {
  color: var(--color-primary);
} */

.arrow,
.star {
  color: var(--color-grey-text);
  font-size: 22px;
  opacity: 0.5;
  cursor: pointer;
}
.arrow {
}
.arrow:hover {
  color: var(--white-color);
  opacity: 1;
}

.activeFavourite {
  color: var(--color-yellow-text);
  opacity: 1;
}
.tabs {
  display: flex;
  gap: 8px;
  background: var(--color-bg-dark);
  overflow-x: auto;
  scroll-behavior: smooth;
}

.tab {
  padding: 4px 8px;
  transition: 0.3s;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
  background: var(--color-bg-dark);
  text-transform: capitalize;
}
.tab:hover {
  background: var(--color-bg-dark-2);
  color: var(--white-color);
}
.activeTab {
  background: var(--color-bg-dark-2);
  color: var(--white-color);
}
.lightTheme .activeTab {
  background: var(--color-bg-dark-4);
}
.lightTheme .tab {
  color: var(--white-color);
}
.star {
  font-size: 16px;
}

@media only screen and (max-width: 520px) {
  .star {
    font-size: 16px;
  }
}
