.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  white-space: normal;
}

.dropdownMain {
  width: 180px;
  padding: 12px;
  height: 0;

  background-color: var(--color-bg-dark-2);
  border: 1px solid var(--color-border-dark);
  box-shadow: 0px 24px 32px -8px var(--shadowColor);
  border-radius: 12px;
  position: absolute;
  right: 0;
  top: calc(100% + 20px);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s ease;
}
.selectedValue {
  text-transform: uppercase;
}
.icon {
  cursor: pointer;
}
@media screen and (max-width: 350px) {
  .dropdownMain {
    /* top: unset; */
    /* right: unset; */
    left: 50%;
    transform: translateX(-50%);
    /* bottom: calc(100% + 8px); */
  }
}

.dropdownMain.active {
  overflow: auto;
  height: auto;
  opacity: 1;
  pointer-events: all;
}
.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.heading {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}
.item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.outerCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-text2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.outerCircle.selected {
  border: 1px solid var(--color-primary);
}
.innerCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.selected .innerCircle {
  background: var(--color-primary);
}
.label {
  color: var(--white-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
}
.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  padding-top: 12px;
  border-top: 1px solid var(--color-border-dark);
  margin-top: 12px;
}
