.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.tabs .tab {
  padding: 8px 0 16px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.tabs .tab.active {
  color: var(--color-primary);
  border-bottom-color: var(--color-primary);
}
