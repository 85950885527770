.wrapper {
  border-radius: 20px;
  border: 1px solid rgba(23, 222, 138, 0.1);
  background: linear-gradient(
    99deg,
    rgba(23, 222, 138, 0.1) 0%,
    rgba(23, 222, 138, 0.05) 100%
  );
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 18px;
  width: 100%;
}
.img {
  max-width: 48px;
  display: block;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.infoContainer h4 {
  color: var(--white-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.556%;
}
.infoContainer p {
  color: var(--white-color);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  opacity: 0.6;
}
.arrow {
  display: block;
  max-width: 24px;
  margin-left: auto;
}
