.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px 15px;
}

.header {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--color-border-dark);
}
.searchContainer {
  position: relative;

  padding-right: 0;
  padding-left: 10px;

  max-width: 270px;
  width: 100%;
  margin-left: auto;
}

.searchContainer .icon {
  position: absolute;
  top: 50%;
  left: calc(16px + 6px);
  transform: translateY(calc(-50% + 2px));
}

.searchContainer input {
  height: 40px;
  border-radius: 6px;
  padding: 10px 12px 10px 36px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  background-color: transparent;
  border: 1px solid var(--color-border-dark);
  outline: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  background: var(--color-bg-dark-3);
}
.searchContainer input:focus {
  border-color: var(--color-primary);
}
.searchContainer input::placeholder {
  color: var(--color-grey-text-light);
}
.policies {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.policy {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.policy .title,
.policy .title span {
  color: var(--white-color);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.policy .info {
  color: var(-- --color-grey-text2);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
